.basic_search_wrap{
  border:1px solid #BCBCBC;
  padding:30px;
}
.basic_search_title_bar{
  font-size:18px;
  color:#7470F9;
  font-weight: bold;
}
.dalirant .basic_search_title_bar{
  color:#02061F;
}
.basic_search_ico{
  display: inline-block;
  height: 22px;
  width: 22px;
  background-image: url(../img/ico/search3.png);
  background-size: cover;
}
.dalirant .basic_search_ico{
  background-image: url(../img/ico/search4.png);
}
.basic_search_title_bar img{
  height: 22px;
  margin:2px 7px;
  margin-top: -1px;
}
.basic_search_box_wrap{
  display: flex;
  flex-direction: row;
  gap:5px;
  margin-top:20px;
}
.basic_search_item_input_wrap{
  flex-grow: 1;
  max-width: 200px;
}
.basic_search_item_book_mark{
  color:blue;
  padding: 3px;
  cursor: pointer;
  line-height: 25px;
  font-size: 15px;
  font-weight: bold;
}
.basic_search_item_input_wrap .search_item_input{
  width:100%;
  padding:10px;
  font-size: 15px;
  border:1px solid #ddd;
}
.dark .basic_search_item_input_wrap .search_item_input{
  background-color: #505050;
  color:#fff;
}
.basic_search_item_input_wrap .search_item_input_select{
  width:100%;
  padding:7px;
  font-size: 14px;
  border:1px solid #ddd;
  min-width: 52px;
  color:#505050;
}
.dark .basic_search_item_input_wrap .search_item_input_select{
  color:#fff;
}
.basic_search_item_input_wrap .search_item_input_select option{
  background: #fff;
}

.basic_tab_wrap{
  width: 100%;
  position: relative;
  overflow: hidden;
}
.basic_tab_wrap table{
  width: 100%;
  border-collapse: collapse;
}
.basic_tab_wrap_bottom_line{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: #7470F9;
}
.basic_tab_wrap .tab_item{
  position: relative;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color:#707070;
  font-weight: 500;
  padding:0 12px;
  border: 2px solid #cecece;
  border-bottom: 2px solid #7470F9;
  cursor: pointer;
}
.basic_tab_wrap .tab_item .tab_item_left_line{
  position: absolute;
  width: 2px;
  height: 110%;
  left: -2px;
  top:-2px;
  background: #7470F9;
}
.basic_tab_wrap .tab_item:hover{
  color: #5aba41;
}
.basic_tab_wrap .tab_item.active{
  border: 2px solid #7470F9;
  color: #7470F9;
}
.basic_tab_wrap .basic_tab_wrap_empty_td{
  border-bottom: 2px solid #7470F9;
}

.basic_tab_wrap2{
  width: 100%;
  position: relative;
}
.basic_tab_wrap2 table{
  width: 100%;
  border-collapse: collapse;
}
.basic_tab_wrap2 th,.basic_tab_wrap2 td{
  border:0;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
}

.basic_tab_wrap2 .tab_item{
  position: relative;
  text-align: center;
  color:#707070;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  border:2px solid #E3E3E3;
  border-bottom: 2px solid #7470F9;
  border-left: 0;
  cursor: pointer;
}
.basic_tab_wrap2 .first_tab_item{
  border-left:2px solid #E3E3E3;
}
.basic_tab_wrap2 .tab_item.active{
  line-height: 45px;
  color:#7470F9;
  border:2px solid #7470F9;
  border-bottom: 0;
}
.basic_tab_wrap2 .tab_item_empty{
  line-height: 35px;
  border-bottom:2px solid #7470F9;
}

.basic_list_title_bar_wrap{
  position: relative;
  margin-top: 20px;
  padding:15px 0px;
}
.basic_title_bar_right_box{
  position: absolute;
  display: inline-block;
  right: 0px;
  top:5px;
}

.basic_list_div{
  width: 100%;
  overflow: auto;
}
.basic_list_div table{
  width: 100%;
  border-collapse: collapse;
}
.basic_list_div thead tr{
  border:1px solid #bdbdbd;
}
.basic_list_div th{
  background: #EFEFEF;
}
.basic_list_div th .row-col-div{
  color:#000;
  padding:10px 5px;
  font-size:15px;
  font-weight: normal;
  line-height: 20px;
  cursor: pointer;
}
.dark .basic_list_div th{
  background: #4d4d4d;
}
.dark .basic_list_div th .row-col-div{
  color:#ffffff;
}

.basic_list_div td{
  border-bottom: 1px solid #bdbdbd;
}
.basic_list_div tbody td:first-child{
  border-left: 1px solid #bdbdbd;
}
.basic_list_div tbody td:last-child{
  border-right: 1px solid #bdbdbd;
}
.basic_list_div .row-col-div{
  padding:10px 5px;
}
.basic_list_div tbody tr.active{
  background: #cee9ff;
}
.basic_list_div tbody tr.active td{
  color:rgb(43, 43, 43);
}
.dark .basic_list_div tbody tr.active{
  background: #6ebeff;
}

.basic_list_div .list_empty_content_div{
  @apply text-center p-3;
}
@media screen and (max-width:700px) {
  .basic_title_bar_right_box{
    position: relative;
    display: block;
    right: 5px;
    top:5px;
    text-align: right;
  }
  .basic_title_bar_right_box button{
    margin-top:1px;
  }
  .basic_list_div .row-col-div{
    padding: 2px;
    font-size: 14px;
    overflow: hidden;
  }
  .basic_list_title_bar_wrap{
    margin-top:0px;
  }
  .basic_tab_wrap2 .tab_item{
    font-size: 10px;
  }

  .basic_list_div th .row-col-div{
    padding:10px 5px;
  }
  .basic_list_div .row-col-div{
    padding:5px 5px;
  }
}
@media screen and (max-width:600px) {
  .basic_search_wrap{
    padding:10px;
  }
  .basic_search_box_wrap{
    margin-top:8px;
  }
  .basic_search_item_input_wrap{
    margin-left:1px;
  }
  .basic_search_item_input_wrap .search_item_input{
    padding:5px;
    font-size: 14px;
  }
  .basic_search_item_input_wrap .search_item_input_select{
    padding:2px;
    font-size: 13px;
  }
}