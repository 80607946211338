.write_wrap{
  position: relative;
  font-size: 14px;
}
.write_wrap input{
  width: 100%;
  height: 100%;
  padding: 0px 2px;
  border:0px;
  font-size: 14px;
  text-align: center;
}
.write_wrap textarea{
  width: 100%;
  height: 100%;
  padding: 0px 2px;
  border:0px;
  font-size: 14px;
  text-align: center;
  resize: none;
  border-radius: 5px;
}
.top_wrap{
  width: 95%;
  margin:0 auto;
}
.top_title_wrap{
  position: relative;
  font-size: 20px;
  color:#0e6d20;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}
.top_title_under_bar{
  width: 100%;
  height: 5px;
  background: rgb(43,230,78);
  background: linear-gradient(90deg, rgba(43,230,78,1) 0%, rgba(32,199,64,1) 28%, rgba(255,255,255,1) 74%);
}
.top_title_right_logo_box{
  position: absolute;
  top:0px;
  right: 0px;
  height: 100%;
  width: 200px;
  text-align: right;
}
.top_stu_table_wrap{
  display: flex;
  gap: 10px;
  margin-top:10px;
}
.top_stu_table_wrap table{
  border-collapse: collapse;
}
.top_stu_table_wrap table th,.top_stu_table_wrap table td{
  border:1px solid #0e6d20;
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  height: 30px;
}
.content_wrap{
  margin-top: 15px;
}
.title_wrap{
  display: flex;
  gap: 5px;
}
.title_num{
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  background: #028db8;
  color:#fff;
  text-align: center;
}
.title_text{
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 3px;
}
.goal_table{
  width: 100%;
  border-collapse: collapse;
}
.goal_table td,.goal_table th{
  border:0px;
  padding:2px;
  text-align: center;
}
.goal_box_green{
  width: 100%;
  height: 100%;
  min-height: 30px;
  border:1px solid #0e6d20;
  border-radius: 5px;
  text-align: center;
}
.thema_title{
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color:#0e6d20;
}
.goal_box_sky{
  width: 100%;
  height: 100%;
  min-height: 60px;
  height: 60px;
  border:2px solid #028db8;
  border-radius: 5px;
  text-align: center;
  font-size: 11px;
  line-height: 13px;
  overflow: hidden;
}
.goal_box_explan{
  width: 100%;
  height: 100%;
  min-height: 60px;
  border:1px solid #108600;
  text-align: center;
  padding:5px;
  line-height: 16px;
}
.goal_box_explan_row{
  position: relative;
  font-size: 12px;
  text-align: left;
  padding-left: 8px;
}
.goal_box_explan_dot{
  position: absolute;
  top:8px;
  left:2px;
  border-radius: 10px;
  width: 4px;
  height: 4px;
  background: #0e6d20;
  display: inline-block;
}
.goal_bottom_explan_text{
  font-size: 15px;
  font-weight: bold;
  color:#0e6d20;
}
.goal_title_box{
  display: inline-block;
  width: 70px;
  height: 50px;
  line-height: 50px;
  background: rgb(25,179,54);
  background: linear-gradient(127deg, rgba(25,179,54,1) 0%, rgba(14,109,32,1) 40%, rgba(6,69,18,1) 100%);
  color:#fff;
  border-radius: 10px;
  font-size: 16px;
}
.goal_title_box_sky{
  display: inline-block;
  width: 90px;
  height: 50px;
  line-height: 50px;
  background: rgb(56,147,224);
  background: linear-gradient(127deg, rgba(56,147,224,1) 0%, rgba(10,181,201,1) 40%, rgba(61,166,221,1) 100%);
  color:#fff;
  border-radius: 10px;
  font-size: 16px;
}