.btn_wrap{
  margin-left:15px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding-top: 3px;
  cursor: pointer;
}
.menu_trigger,
.menu_trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.menu_trigger {
  position: relative;
  width: 20px;
  height: 16px;
}

.menu_trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #9d9c9c;
  border-radius: 2px;
}

.menu_trigger span:nth-of-type(1) {
  top: 0px;
}

.menu_trigger span:nth-of-type(2) {
  top: 7px;
}

.menu_trigger span:nth-of-type(3) {
  bottom: 0px;
}

.menu_trigger.active{
  background:#7470F9;
}
/* 중앙 라인이 고정된 자리에서 투명하게 사라지며 상하라인 회전하며 엑스자 만들기 */
.menu_trigger.active span:nth-of-type(1) {
  -webkit-transform: translateY (7px) rotate (-45deg);
  transform: translateY(7px) rotate(-45deg);
  background:#fff;
}

.menu_trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.menu_trigger.active span:nth-of-type(3) {
  -webkit-transform: translateY(-7px) rotate(45deg);
  transform: translateY(-7px) rotate(45deg);
  background:#fff;
}

@media screen and (max-width:700px) {
  .btn_wrap{
    display: none;
  }
}