.chat_wrap{
  font-size: 14px;
}
/* head */
.chat_header{
  background:#9aa700;
  width: 100%;
}
.chat_header_con{
  @apply max-w-5xl flex flex-row shadow-md;
  min-width:var(--min_width);
  background:#9aa700;
  margin:0 auto;
  height: 60px;
  line-height: 60px;
}
.chat_head_icon_wrap{
  @apply grow-0;
  position: relative;
  width: 120px;
}
.chat_head_icon{
  position: absolute;
  height: 40px;
  top:10px;
  left:10px;
}
.chat_head_search_wrap{
  @apply grow;
  text-align: center;
}
.chat_head_search_input_wrap{
  display: inline-block;
  position: relative;
  width: 45%;
  min-width: 200px;
  height: 100%;
}
.chat_head_search_input{
  position:absolute;
  top:15px;
  left:0px;
  width: 100%;
  padding-left:40px;
  border-radius: 5px;
  border:0;
  height: 30px;
  font-size: 14px;
}

.chat_head_search_input:focus{
  outline: none;
}
.chat_head_close_wrap{
  @apply grow-0;
  width: 60px;
  text-align: right;
  position: relative;
}
.chat_head_close_btn{
  padding:10px;
  margin-right: 10px;
  color:#474b04;
  font-weight: bold;
  font-size: 18px;
}
.chat_head_close_btn:hover{
  color:aliceblue;
}

/* Body */
.chat_body{
  width: 100%;
}
.chat_body_con{
  @apply flex flex-row shadow-md;
  min-width:var(--min_width);
  max-width: 1080px;
  height: 80vh;
  margin:0 auto;
}
.chat_left_menu_wrap{
  @apply grow-0;
  width: 40px;
  border-right: 1px solid rgb(222, 218, 218);
}
.chat_left_menu_item{
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border:1px solid rgb(222, 218, 218);
  cursor: pointer;
}
.chat_left_menu_item:hover{
  background: #2aa700;
  color: white;
}
.chat_left_menu_item.active{
  background: #9aa700;
  color: white;
}
.chat_left_user_wrap{
  @apply grow-0;
  width: 220px;
  border-right: 1px solid rgb(222, 218, 218);
  overflow: auto;
}
.chat_left_room_wrap{
  @apply grow-0;
  width: 280px;
  border-right: 1px solid rgb(222, 218, 218);
}
.chat_center_wrap{
  @apply grow;
  position: relative;
  height: 80vh;
  display: flex;
  flex-direction: column;
}