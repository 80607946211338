.time_table_wrap{
  
}
.time_table_wrap table{
  border-collapse: collapse;
}
.time_table_wrap table td,.time_table_wrap table th{
  border:1px solid #ddd;
  padding: 0px;
}
.time_table_th_box{
  min-width: 90px;
  min-height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  padding:0px 5px;
}

.time_table_td_time_box{
  position: relative;
  width: 100px;
  min-height: 50px;
  text-align: center;
}
.time_table_td_box{
  position: relative;
  width: 210px;
  min-height: 50px;
  background: #f9f8f8;
}

.time_table_lecture_box{
  position: absolute;
  top:0px;
  left:0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
  z-index: 1;
  overflow: auto;
}
.time_table_lecture_title{
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
.time_table_lecture_stu_wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding:5px;
}
.time_table_lecture_stu_row{
  display: inline-block;
  padding:0px 3px;
  line-height: 20px;
}