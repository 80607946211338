.room_wrap{
  position: relative;
  text-align: center;
  line-height: 20px;
  margin:5px;
}
.room_box{
  display: inline-block;
  padding: 0 6px;
  text-align: center;
  background: #ddd;
  color:black;
  margin-left: 10px;
  margin-top:5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  cursor: pointer;
}
.room_box:first-child{
  margin-left: 0px;
}

.room_box.active{
  background: #7470F9;
  color:#fff;
}
