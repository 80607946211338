.list_wrap{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.list_box{
  border: 1px solid #eee;
  border-radius: 5px;
  padding:5px;
}
.list_box_title{
  line-height: 20px;
}
.qna_span{
  font-size: 15px;
  color:blue;
  margin-right: 2px;
}
.list_box_content{
  padding:5px 0px;
  color:#444;
}
.list_box_bottom{
  font-size: 12px;
  color:#888;
  text-align: center;
}