.box_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.box{
  margin-top:10px;
  flex-grow: 1;
}
.box_center{
  flex-grow: 0;
  width: 60px;
  text-align: center;
  padding-top: 200px;
}
.box_center_img{
  cursor: pointer;
  height: 45px;
  width: 30px;
}

.left_top_btn_wrap{
  position: relative;
  height: 30px;
  line-height: 30px;
}

@media screen and (max-width:800px) {
  .box_wrap{
    flex-direction: column;
  }
}