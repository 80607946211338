.receiver_wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.receiver_item{
  margin:5px;
  padding:4px;
  background: rgb(229, 250, 208);
  border-radius: 5px;
  font-weight: bold;
  border:1px solid #ddd;
}
.receiver_item_idx{
  font-size: 12px;
  color:#888;
  margin-right: 2px;
}