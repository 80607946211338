.list_round_box_wrap{
  border:1px solid #ddd;
  border-radius: 5px;
}
.list_round_box_wrap .list_box_title_bar{
  position: relative;
  padding:15px;
  border-bottom: 1px solid #ccc;
}
.list_round_box_wrap .list_box_title_bar_title{
  font-size: 16px;
  font-weight: bold;
}
.list_round_box_wrap .list_box_title_right_box{
  position: absolute;
  display: inline-block;
  right: 10px;
  top:15px;
}
.list_round_box_wrap .list_round_box{
  padding:10px;
}

.search_round_box_wrap{
  border:1px solid #ddd;
  border-radius: 5px;
}
.search_round_box_wrap .search_round_title_bar{
  width: 100%;
  padding:14px 20px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}
.search_round_box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding:10px;
}
.search_round_box .search_item{
  display: flex;
  width:32%;
  min-width: 300px;
  padding:8px 0px;
}
.search_round_box .search_item_title{
  width: 18%;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  min-width: 100px;
  padding-top:4px;
}
.search_round_box .search_item_input_wrap{
  flex-grow: 1;
}
.search_round_box .search_item_input{
  width: 80%;
  font-size: 15px;
  padding:3px;
  border:1px solid #ddd;
  border-radius: 3px;
}
.dark .search_round_box .search_item_input{
  background-color: #222;
  color:#cfcfcf;
}
.search_round_box .search_item_input_select{
  width: 80%;
  font-size: 15px;
  border:1px solid #ddd;
  border-radius: 3px;
}
.dark .search_round_box .search_item_input_select{
  background-color: #222;
  color:#cfcfcf;
}

@media screen and (max-width:600px) {
  .list_round_box_wrap .list_box_title_right_box{
    position: relative;
  }
}