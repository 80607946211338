.home_list_table{
  width: 100%;
  overflow: auto;
}
.home_list_table table{
  width: 100%;
  border-collapse: collapse;
}
.home_list_table table th,.home_list_table table td{
  text-align: center;
  border:1px solid #c9c9c9;
  border-left: 0;
  border-right: 0;
  font-size: 15px;
  padding:8px;
}
.home_list_table table th{
  font-weight: bold;
  background-color: rgb(239, 239, 239);
}
.dark .home_list_table table th{
  background-color: #555;
  color: #fff;
}
.home_list_table table td{
  text-align: left;
}