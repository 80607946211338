.section_div{
  position: relative;
}
.section_title{
  color:#fff;
  background:#ababab;
  padding:5px 10px;
  font-size:15px;
  font-weight: bold;
}
.video_div{
  padding:2px;
  padding-left: 10px;
  padding-bottom: 0px;
  font-size:15px;
  color:#000;
  background: #fff;
  border-bottom: 1px solid #ddd;
}
.video_user_bar{
  position: relative;
  height: 20px;
  background: #eee;
}
.video_user_bar_text{
  position: absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  text-align: center;
  text-align: center;
  color:#000;
  font-size: 12px;
  line-height: 20px;
  z-index:2;
}
.video_user_bar_per{
  position: absolute;
  height: 100%;
  z-index: 1;
  background: rgb(175, 255, 99);
  left:0px;
  top:0px;
}