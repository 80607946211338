.dashboard_list_div{
  width: 100%;
  overflow: auto;
}
.dashboard_list_div table{
  width: 100%;
  border-collapse: collapse;
}
.dashboard_list_div thead tr{
  border:1px solid #bdbdbd;
}
.dashboard_list_div th .row-col-div{
  color: #707070;
  padding:10px 5px;
  font-size:15px;
  font-weight: normal;
  line-height: 20px;
}
.dark .dashboard_list_div th{
  background: #4d4d4d;
}
.dark .dashboard_list_div th .row-col-div{
  color:#ffffff;
}

.dashboard_list_div td{
  border-bottom: 1px solid #bdbdbd;
}
.dashboard_list_div tbody td:first-child{
  border-left: 1px solid #bdbdbd;
}
.dashboard_list_div tbody td:last-child{
  border-right: 1px solid #bdbdbd;
}
.dashboard_list_div .row-col-div{
  padding:10px 2px;
  font-size: 15px;
}
.dashboard_list_div tbody tr.active{
  background: #cee9ff;
}
.dashboard_list_div tbody tr.active td{
  color:rgb(43, 43, 43);
}
.dark .dashboard_list_div tbody tr.active{
  background: #6ebeff;
}

.dashboard_list_div .list_empty_content_div{
  @apply text-center p-3;
}