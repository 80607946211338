.home_con_wrap{
  width: 100%;
  padding-top: 60px;
}
.home_con_wrap .home_con{
  width:100%;
  max-width: 1400px;
  margin:0 auto;
  border-top: 1px solid #ddd;
  display: flex;
}
.home_con_left{
  width: 200px;
}
.home_con_center{
  flex-grow: 1;
  min-width: 400px;
}

@media screen and (max-width:700px) {
  .home_con_wrap .home_con{
    flex-direction: column;
  }
  .home_con_left{
    width: 100%;
  }
}