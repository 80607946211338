.plan_cal_wrap table{
  border-collapse: collapse;
  width: 100%;
}
.plan_cal_wrap th, .plan_cal_wrap td{
  border:1px solid #C4C4C4;
  text-align: center;
  vertical-align: top;
}
.plan_cal_wrap .td_title_now_date{
  border:3px solid #49A894;
}
.plan_cal_wrap th{
  background: #EFEFEF;
  color:#505050;
  padding:5px;
  font-weight: 500;
}

.th_text{
  min-height: 25px;
  line-height: 25px;
  font-size: 15px;
}
.th_text_sat{
  color:#0058FF;
}
.th_text_sun{
  color:#E22F04;
}

.td_title{
  color:#C4C4C4;
  text-align: left;
  padding:5px;
  font-weight: 500;
  position: relative;
}
.td_title_now_date .td_title{
  color:#49A894;
}
.td_title_select_month{
  color:black;
}
.td_content_wrap{
  min-height: 10vh;
  position: relative;
}
.td_title_select_month.td_title_sat{
  color:#0058FF;
}
.td_title_select_month.td_title_sun{
  color:#E22F04;
}

.td_todo_stu_name{
  text-align: left;
  background: #cceaf1;
  color:#000000;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  padding:0px 5px;
  opacity: 0.8;
  cursor: pointer;
}
.td_todo_con_box{
  margin-bottom: 5px;
}
.td_todo_row{
  position: relative;
  text-align: left;
  padding:1px 10px;
  font-size: 13px;
  width: 100%;
  overflow: hidden;
}
.td_todo_row:first-child{
  margin-top: 4px;
}
.td_todo_row.todo_row_complete .td_todo_row_state{
  /* background: #f4faad; */
}
.td_todo_row.todo_row_stop .td_todo_row_state{
  /* background: #ffdcd4; */
}
.td_todo_row.todo_row_ing .td_todo_row_state{
  /* background: #d9fffe; */
}
.td_todo_row_title{
  position: absolute;
  right: 12px;
  top:3px;
  max-width: 50%;
  min-width: 50%;
  max-height: 18px;
  color:#505050;
  cursor: pointer;
  font-size: 13px;
  text-overflow: ellipsis;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
}
.td_todo_row_state{
  font-size: 12px;
  line-height: 20px;
  height: 21px;
  background: #f6fafc;
}
.td_todo_row_state_ico{
  height: 86%;
  margin-right: 5px;
  padding-bottom: 1px;
}

.top_year_month_wrap{
  width: 100%;
}

.year_span_wrap{
  display: flex;
  justify-content: center;
  line-height: 38px
}
.center_ym_span{
  @apply px-1;
  font-size: 20px;
  color:#7470F9;
}
.center_ym_span select{
  color:#7470F9;
  background: #fff;
  border: 0;
  font-size: 25px;
  font-weight: bold;
  padding: 2px;
  text-align-last: center;
  -webkit-appearance:none; /* 크롬 화살표 없애기 */
  -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
  appearance:none /* 화살표 없애기 */
}
.center_ym_span select option{
  font-size:18px;
}
.year_arrow_btn{
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 43px;
}
.year_arrow_btn img{
  height: 30px;
  padding:5px;
  border:2px solid #ddd;
  border-radius: 5px;
}
.top_year_month_side_span{
  font-size: 17px;
  color:#505050;
  line-height: 43px;
  font-weight: 500;
}

.plan_cal_middle_wrap{
  position: relative;
  min-height: 45px;
}
.plan_cal_middle_stu{
  position: absolute;
  width: 80%;
  min-height: 20px;
  bottom: 5px;
  left:0px;
}
.plan_cal_middle_stu_span{
  height: 20px;
  line-height: 22px;
  padding:0px 5px;
  color:#242424;
  background: #f6f6f6;
  margin-left:10px;
  opacity: 0.8;
  border:1px solid #dfdfdf;
}
.plan_cal_middle_tab_wrap{
  position: absolute;
  width: 80%;
  min-height: 20px;
  bottom: 5px;
  left:0px;
}
.plan_cal_middle_tab_btn{
  font-size: 14px;
  line-height: 30px;
  padding:0 25px;
  border:1px solid #ccc;
  border-radius: 5px;
  color:#7470F9;
  border-color: #7470F9;
  font-weight: 600;
}
.plan_cal_middle_tab_btn.active{
  background: #7470F9;
  color:#fff;
}
.plan_cal_middle_right{
  position: absolute;
  display: inline-block;
  min-width: 50px;
  right: 0px;
  bottom:5px;
}
.plan_cal_middle_right_state_span{
  display: inline-block;
  line-height: 25px;
  padding: 0 10px;
  color:#fff;
  font-size: 14px;
  background: #A2B854;
  font-weight: 400;
}

.todo_sum_area{
  position: relative;
  padding:3px;
  cursor: pointer;
}
.todo_sum_title{
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}
.todo_sum_con_wrap{
  position: relative;
  line-height: 25px;
  font-size: 12px;
  padding:0 15px;
  background: #F0F2CD;
  color:#000;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 25px;
  margin-top:3px;
}
.todo_sum_con_bar{
  position: absolute;
  width: 5px;
  height: 100%;
  top:0px;
  left:0px;
  background: #B3C019;
}
.todo_sum_per_span{
  position: absolute;
  right: 5px;
  top:0px;
  color:#ABB634;
}

.todo_sum_area_hover{
  position: fixed;
  top:0px;
  left: 0px;
  width: 150px;
  height:200px;
  border:1px solid #ccc;
  border-radius: 5px;
  padding:5px;
  z-index: 1;
  background: #fff;
  overflow-y: auto;
  display: none;
}

@media screen and (max-width:800px) {
  .td_todo_row{
    padding: 1px 0px;
  }
  .td_todo_row_title{
    position: relative;
    right: 0;
    top:0;
  }
  .top_year_month_side_span{
    display: none;
  }

  .todo_sum_area{
    padding: 0px;
  }
  .todo_sum_con_title{
    display: none;
  }
  .todo_sum_con_wrap{
    padding:0 6px;
  }
  .todo_sum_per_span{
    right: 0px;
    font-size: 12px;
  }
}