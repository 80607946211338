.popup_wrap{
  width: 95%;
  margin:0 auto;
}
.sub_title{
  font-weight: bold;
  font-size: 14px;
  margin-top:5px;
}
.stu_wrap{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
  padding:5px;
}
.stu_item{
  display: inline-block;
  line-height: 30px;
  padding:0px 5px;
  background: #7514e3;
  color:#fff;
  border-radius: 5px;
  font-size: 15px;
}