.cate_title_wrap{
  cursor: pointer;
}
.cate_title_wrap:hover{
  background: #00968b;
  color:#fff;
}
.cate_title_wrap.active{
  background: #00968b;
  color:#fff;
}

.check_title_wrap{
  cursor: pointer;
}
.check_title_wrap:hover{
  background: #00968b;
  color:#fff;
}
.check_title_wrap.active{
  background: #00968b;
  color:#fff;
}