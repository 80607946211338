.box_wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap:20px;
    width: 100%;
}
.box{
  flex-grow: 1;
  flex-wrap: wrap;
  width: 49%;
  min-width: 300px;
  display: flex;
  gap:10px;
  border-radius: 10px;
  background: #f5f5f5;
  border:1px solid #ddd;
  padding: 5px;
}
.list_item{
  display: inline-block;
  min-width: 100px;
  padding:0px 5px;
  margin-top:5px;
  line-height: 25px;
  cursor: pointer;
}
.list_item.is_select{
  color:#a52a2a;
  font-weight: bold;
}