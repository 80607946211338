.card_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card_item{
  max-width: 520px;
  width: 100%;
  height: 285px;
  border:1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  margin-top:10px;
}
.card_item:hover{
  cursor: pointer;
}
.card_item_category_wrap{
  position: relative;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  width: 100%;
  padding-left: 15px;
  background:#AFBA57;
  color:#FFFFFF;
}
.card_item_title_right_per{
  position: absolute;
  right: 10px;
  top:0px;
  font-size: 14px;
}
.card_item_title_img_wrap{
  width: 100.5%;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
}
.card_item_title_img_wrap img{
  width:100%;
  height:auto;
}
.card_complete_per_wrap{
  position: relative;
  height: 8px;
  background:#d8dbd0;
}
.card_complete_per_bar{
  position: absolute;
  height: 100%;
  background:#A1AC36;
  width:0px;
  left:0px;
  top:0px;
}
.card_bottom_box{
  padding: 11px 12px;
  padding-top: 14px;
}
.card_title_span{
  font-size:16px;
  font-weight: bold;
  color:#000;
}
.card_title_state{
  font-size:14px;
  color:#7e872b;
  font-weight: 100;
  margin-left:2px;
}
.card_title_state_after{
  margin-left:2px;
  color:#A1AC36;
  font-size:14px;
}
.card_sub_title{
  font-size:14px;
  color:#888;
}