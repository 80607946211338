/* left user */
.chat_user_profile_wrap{
  width: 100%;
  text-align: center;
  padding-top:10px;
  padding-bottom: 10px;
}
.chat_user_profile_div{
  display: inline-block;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 70%;
  background: gray;
}
.chat_user_profile_div img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat_left_user_list_wrap{
  min-height: 40px;
}
.chat_left_user_list_title{
  font-weight: bold;
  position: relative;
  background: #f2f2f2;
  height: 40px;
  line-height: 40px;
  padding-left:30px;
  cursor: pointer;
}
.chat_left_user_list_title_left_bar{
  position: absolute;
  left:0px;
  top:0px;
  display: inline-block;
  width:5px;
  height: 100%;
  background: #017d8c;
}
.chat_left_user_list_title_arrow{
  position: absolute;
  right: 10px;
  color:gray;
}
.chat_left_user_list_div{

}
.chat_left_user_row{
  @apply flex flex-row;
  height: 45px;
  margin-top:5px;
  cursor: pointer;
}
.chat_left_user_row_profile_wrap{
  @apply grow-0;
  width: 60px;
  height: 45px;
  text-align: center;
}
.chat_left_user_row_profile_img_box{
  display: inline-block;
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 70%;
  background: rgb(255, 255, 255);
}
.chat_left_user_row_profile_img_box img{
  width: 100%;
  object-fit: cover;
}
.chat_left_user_row_con_wrap{
  @apply grow;
  padding-left: 5px;
}
.chat_left_user_row_tel{
  color:gray;
}
