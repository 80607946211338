.wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 50px;
}
.box{
  flex-grow: 1;
  max-width: 450px;
  min-width: 400px;
  min-height: 300px;
  border:1px solid #ccc;
  margin-top:10px;
  margin-left:10px;
  padding:10px;
}