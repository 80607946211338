.video_page{
  background: #fff;
  font-size: 14px;
}
.top_wrap{
  position: relative;
  width: 100%;
  line-height: 30px;
  height: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color:#fff;
  text-align: center;
  background: linear-gradient(253deg, #C8D376 0%, #ABC118 37.41%, #86A609 84.51%);
}
.top_wrap_back_btn{
  position: absolute;
  display: inline-block;
  left: 0px;
  top:0px;
  height: 100%;
  width: 50px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}

.video_wrap{
  position: relative;
}
.video_title_wrap{
  margin-top:5px;
  line-height: 25px;
  font-size: 15px;
  font-weight: bold;
  padding:0px 20px;
  color: #000;
}
.video_player_wrap{
  width: 100%;
}
.video_progress_bar_wrap{
  width: 100%;
  position: relative;
  height: 5px;
}
.video_time_wrap{
  position: relative;
  height: 20px;
  line-height: 20px;
}
.video_time_span{
  position: absolute;
  right: 8px;
  color:#6F6F6F;
  font-size: 12px;
}
.video_progress_bar_back{
  width: 100%;
  position: absolute;
  top:0px;
  left:0px;
  height: 5px;
  background: #E1E6B0;
}
.video_progress_bar{
  width: 0%;
  position: absolute;
  top:0px;
  left:0px;
  height: 5px;
  background: #9EAF25;
}
.video_sub_title_wrap{
  position: relative;
  margin-top:10px;
  line-height: 30px;
  border-top:1px solid #ECECEC;
  border-bottom:1px solid #ECECEC;
  padding:0px 20px;
  min-height: 30px;
  font-weight: bold;
  font-size: 14px;
  padding-right: 80px;
}
.video_sub_title_right_state{
  position: absolute;
  right: 20px;
  top:0px;
  font-size: 12px;
  color: #6F6F6F;
  font-weight: 500;
}
.video_memo_wrap{
  margin-top:10px;
  padding:0px 20px;
  color:#6F6F6F;
  line-height: 25px;
  height: 45px;
  text-overflow: ellipsis;
}
.bottom_con_wrap{
  position: relative;
  padding:0px 20px;
  margin:5px 0px;
}
.bottom_con_date{
  color:#6F6F6F;
}
.bottom_btn_wrap{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding:0px 20px;
}
.bottom_btn{
  display: inline-flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 99px;
  background: #E4E4E4;
  font-weight: 500;
  flex-grow: 1;
}