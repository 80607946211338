.popup-background{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  background-color: transparent;
  z-index: 99;
  overflow: hidden;
}
.popup-con-area{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.popup-transparent-back{
  position: fixed;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.popup-wrap{
  position: absolute;
  left:0px;
  top:0px;
}
.popup-container{
  @apply relative bg-gray-100 dark:bg-gray-800;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.popup-title-bar{
  @apply w-full;
  height: 70px;
  z-index: 10;
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dark .popup-title-bar{
  background: #252525;
}
.popup-title-bar .con-title{
  font-size: 18px;
  margin-left: 30px;
  font-weight: 700;
  line-height: 70px;
}
.popup-title-close-btn{
  @apply absolute;
  top:10px;
  right:15px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  color:#707070;
}
.popup-title-close-btn::after{
  display: inline-block;
  content: "\00d7";
  font-size:32px;
}

.popup-content-div{
  @apply text-lg bg-white dark:text-white dark:bg-gray-900 w-full h-full overflow-auto p-2;
  flex-grow: 1;
  padding:30px;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 10px;
}

@media screen and (max-width:700px) {
  .popup-content-div{
    padding-left: 5px;
    padding-right: 5px;
  }
}