.top_info_wrap{
  display: flex;
  justify-content: center;
  gap:10px;
}
.top_info_span{
  font-size: 17px;
}

.map_detail_wrap{
  padding-left: 10px;
}
.map_detail_title{
  font-size: 20px;
  font-weight: bold;
  margin-top: 12px;
}
.map_detail_con{
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
}

.map_detail_memo_span{
  display: inline-block;
  padding:0px 5px;
  border-radius: 5px;
  color:#fff;
  background: #9f9f9f;
  margin-left: 10px;
  margin-top:4px;
}

.map_input_textarea{
  border:1px solid #eee;
}