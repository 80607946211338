.day_of_week_table{
  border-collapse: collapse;
  width: auto;
}
.day_of_week_table td{
  border: 0;
  padding: 0;
  margin: 0;
}
.day_td{
  border: 1px solid #C5C5C5;
}
.td_box{
  color:black;
  padding: 0 15px;
  line-height: 35px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.td_box.active{
  color: #fff;
  background: #7470F9;
}
@media screen and (max-width:700px) {
  .td_box{
    padding: 0 10px;
    line-height: 30px;
  }
}