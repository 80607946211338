@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
*{
  font-size: 14px;
}
body{
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
}

body img,body video{
  display: inline-block;
}

body h1,h2,h3,h4,h5{
  /* white-space:nowrap; */
  font-weight: 500;
}
body h1{
  @apply text-3xl;
}
body h2{
  @apply text-2xl;
}
body h3{
  @apply text-xl;
}
body h4{
  @apply text-lg;
}
body h5{
  @apply text-base;
}

body a{
  cursor: pointer;
}

body input{
  border:1px solid #ddd;
  color:#0b0000;
  accent-color: #7470F9;
  font-weight: 400;
}
body input[type=radio]{
  accent-color: #7470F9;
}
.dalirant input{
  accent-color: #02061F;
}
.dalirant input[type=radio]{
  accent-color: #02061F;
}
body select{
  font-weight: 400;
}
.dark body input{
  background: #636363;
  color:#dcdcdc;
}
.dark select{
  background: #636363;
  color:#dcdcdc;
}

.main_color{
  color: #7470F9;
}
.main_back_color{
  background: #CDCBFF;
}
.sub_color{
  color: #d9e8ef;
}
.sub_text_color{
  color: #000000;
}
.main_text_color{
  color:#7470F9;
}
.logo_background{
  background: #ea5a2f;
}
.dalirant .main_color{
  color: #02061F;
}
.dalirant .main_back_color{
  background-color: #CDCBFF;
}
.dalirant .sub_color{
  color: #d9e8ef;
}
.dalirant .sub_text_color{
  color: #000000;
}
.dalirant .main_text_color{
  color:#7470F9;
}