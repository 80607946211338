.top_area{
  font-size: 15px;
}
.top_area_title{
  font-weight: bold;
  font-size: 20px;
}
.top_area_comp_manage_btn{
  position: absolute;
  right:12px;
  top:8px;
  color:#a1a1a1;
  font-size: 15px;
}

.total_cnt_box{
  display: inline-block;
  padding:5px;
  font-size:17px;
  margin-left:20px;
}
.total_cnt_box_title{

}
.total_cnt_box_num{
  color:#9aa700;
  padding-left: 10px;
  font-weight: bold;
}