.time_table_wrap table{
  border-collapse: collapse;
}
.time_table_wrap table td,.time_table_wrap table th{
  border:1px solid #ddd;
  padding: 0px;
}
.time_table_wrap table td{
  background: #ffffff;
  vertical-align: top;
}
.time_table_th_box{
  min-width: 90px;
  min-height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  padding:0px 5px;
  background: #ffffff;
}
.time_table_td_time_box{
  position: relative;
  width: 90px;
  min-height: 25px;
  text-align: center;
}
.time_table_wrap table .time_table_td{
  background: #f3f3f3;
}
.time_table_wrap table .time_table_td.has_time_td{
  background: #ffffff;
}
.time_table_td_box{
  position: relative;
  min-width: 120px;
  min-height: 25px;
}

.time_table_lecture_title{
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}
.time_table_lecture_stu_wrap{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding:2px;
  max-width: 200px;
  justify-content: center;
  margin:0 auto;
}
.time_table_lecture_stu_row{
  display: inline-block;
  padding:0px 2px;
  line-height: 18px;
  font-size: 12px;
}
.time_table_lecture_stu_row span{
  font-size: 12px;
}