.site_map_wrap{
  position: absolute;
  width: 100%;
  min-height: 100px;
  background-color: #7470F9;
  z-index: 10;
}
.site_map_con{
  min-width:1400px;
  max-width:1400px;
  margin:0 auto;
}
.item_wrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 55px;
}
.item_box{
  flex-grow: 1;
  text-align: center;
  padding:20px 0px;
}
.item_box_con{
  border-right: 1px solid #b9b9b9;
  height: 100%;
}
.item_box:last-child .item_box_con{
  border-right: 0;
}
.item{
  font-size: 15px;
  margin-top:8px;
  color:#fff;
  cursor: pointer;
}
.item:hover{
  color: #f2ff68;
}

@media screen and (max-width:1400px) {
  .site_map_con{
    min-width:auto;
    max-width:auto;
    margin:0;
    display: flex;
    flex-direction: row;
  }
  .item{
    font-size: 13px;
  }
}