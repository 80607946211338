.diary_wrap{
  width: 1024px;
  margin:0 auto;
  margin-top:10px;
}
.list_box_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.box{
  padding:10px;
  border:1px solid #ccc;
  border-radius: 5px;
}

.write_wrap{
  margin:5px 0px;
  padding:10px;
  border:1px solid #759eff;
  border-radius: 5px;
}