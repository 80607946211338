.file_area_wrap{
  position: relative;
  width: 94%;
  margin:0 auto;
  padding:5px;
}
.file_list_wrap{
  width: 100%;
  margin-bottom: 10px;
}
.file_form_wrap{
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: nowrap;
}

.file_write_wrap{
  display: inline-block;
  margin-left: 5px;
  min-height: 20px;
  padding:5px;
  font-size: 14px;
}

.file_form_item{
  position: relative;
  width: 100%;
  text-align: center;
  display: inline-block;
}
.file_form_item audio{
  display: inline-block;
}