.section_wrap{
  margin-top:2px;
}
.section_title_wrap{
  line-height: 22px;
}
.section_title_span{
  color:gray;
  font-size: 14px;
}
.study_list_wrap{
  padding-left: 20px;
}
.study_list_title{
  display: inline-block;
  font-size: 14px;
  color: #000000;
  width: 100%;
  line-height: 22px;
  padding:0px 5px;
}
.study_list_stu_wrap{
  display: flex;
  flex-wrap: wrap;
  gap:5px;
  padding:5px;
}
.study_list_stu_box{
  display: inline-block;
  font-size: 14px;
  color: #ffffff;
  min-width: 80px;
  line-height: 22px;
  padding:0px 5px;
  border-radius: 5px;
  background: #7a7a7a;
  text-align: center;
  cursor: pointer;
}
.study_list_stu_box:hover{
  background: #515151;
}