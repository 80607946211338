.view_wrap{
  background: #c2cd74;
  color:#fff;
  padding:15px 15px;
}
/*Title*/
.view_title_wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  height: 50px;
}
.view_title_img_wrap{
  width: 50px;
  height: 50px;
}
.view_title_img_box{
  background:"#fff";
  width:80%;
  height:80%;
  padding: 10%;
  border-radius: 25px;
  text-align: center;
  line-height: 25px;
}
.view_title_center_wrap{
  flex-grow: 1;
}
.view_title_right{
  width:100px;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 40px;
}
/*Clock*/
.view_clock_wrap{
  height: 320px;
}
.view_clock_box{
  position: relative;
  text-align: center;
  margin:0 auto;
  margin-top:10px;
  width: 300px;
  height: 300px;
  background: #f4f3ed;
  border-radius: 150px;
}
.view_clock_time_wrap{
  display: inline-block;
  margin-top:100px;
  width: 250px;
  height: 120px;
  color:#9eaf25;
  line-height: 60px;
}
.clock_time_div{
  font-size:40px;
  font-weight: bold;
}