.counsel_todo_td_wrap{
  position: relative;
}
.ctodo_td_title_wrap{
  text-align: left;
  color:#508B87;
  font-weight: bold;
  padding-left: 5px;
  cursor: pointer;
}
.ctodo_td_center_plus_btn{
  line-height: 28px;
  background:#707070;
  padding:0 12px;
  border-radius: 5px;
  color:#fff;
  font-size: 14px;
  white-space:nowrap;
  text-align: center;
}
.ctodo_td_list{
  margin-top: 2px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: none;
}
.ctodo_td_todo_row{
  position: relative;
  text-align: left;
  line-height: 22px;
  padding:0 5px;
  background: #707070;
  color:#fff;
  margin:5px;
  font-weight: 300;
  border-radius: 5px;
}
.ctodo_td_todo_row_sub_text{
  font-size: 11px;
  color:#000000;
  font-weight: bold;
  margin-left: 3px;
}
.ctodo_td_todo_row_state_box{
  position: absolute;
  right: 0px;
  top:0px;
  height: 100%;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  color:#ffffff;
  cursor: pointer;
  padding: 0px 3px;
  border:1px solid #0000001b;
}
@media screen and (max-width:700px) {
  .ctodo_td_todo_row{
    font-size: 12px;
    padding:0px;
    text-align: center;
    width: 60px;
  }
  .ctodo_td_todo_row_state_box{
    display: none;
  }
  .ctodo_td_todo_row_sub_text{
    display: none;
  }
  .ctodo_td_center_plus_btn{
    padding:0 3px;
    font-size: 13px;
  }
}