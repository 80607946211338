.top_info_wrap{
  position: relative;
  font-size: 15px;
  text-align: center;
  line-height: 27px;
}

.map_list_row{
  margin-top: 8px;
  padding:5px;
  padding-left: 10px;
  background: #eeee;
  border-radius: 10px;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
}

.section_row{
  margin-top: 10px;
}
.section_study_area{
  width: 98%;
  margin: 0 auto;
}
.section_title_wrap{
  position: relative;
  font-size: 16px;
  color:gray;
  line-height: 25px;
  cursor: pointer;
}

.study_detail_row{
  position: relative;
  margin-top: 10px;
  padding-left: 10px;
  font-size: 18px;
}
.study_detail_con{
  background: #eeee;
  border-radius: 10px;
  padding-left: 15px;
  line-height: 30px;
  cursor: pointer;
}
.study_detail_idx{
  font-size: 18px;
  color:#000000;
}
.study_detail_title_wrap{
  font-size: 18px;
  color:#000000;
  margin-left: 5px;
}
.study_detail_right{
  position: absolute;
  right: 10px;
  display: inline-block;
  line-height: 30px;
  min-width: 50px;
  min-height: 30px;
  text-align: right;
}