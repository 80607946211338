.room_wrap{
  @apply flex gap-x-4;
}
.room_left_wrap{
  flex:1;
  min-width: 220px;
}
.room_center_wrap{
  flex:2;
  min-width: 400px;
}