.card_list_wrap{
  position: relative;
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}
.empty_box{
  line-height: 35px;
  text-align: center;
  width: 100%;
  font-size: 16px;
  color:#aaa;
}
.card_box{
  position: relative;
  max-width: 300px;
  min-width: 300px;
  min-height: 250px;
}
.card_box_back{
  position: absolute;
  background: #D0D0D0;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
}
.card_box_con{
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding:10px;
  color:#000;
  font-size: 15px;
}
.top_stu_info_wrap{

}
.top_stu_info_profile_wrap{
  width: 170px;
  height: 170px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid #e8e8e8;
  border-radius: 5px;
  background: #f9f9f9;
}
.top_stu_info_profile_wrap img{
  width: 100%;
}
.top_stu_info_right{
  padding-left:10px;
  line-height: 25px;
}
.top_stu_info_right_title{
  font-size: 15px;
  font-weight: 500;
}
.top_stu_info_class_text{
  margin-top:5px;
  display: inline-block;
  padding:0px 7px;
  line-height: 25px;
  background: #5c6f82;
  color:#fff;
  border-radius: 5px;
}

.sub_info_wrap{
  margin-top:10px;
}
.sub_info_table{
  width: 100%;
}
.sub_info_table th,.sub_info_table td{
  text-align: left;
  padding:5px;
  font-size: 13px;
}
.sub_info_table td{
  color:#333333;
}

.cousel_box_wrap{
  margin-top:10px;
}
.cousel_box_title{
  color:#999;
  font-size: 15px;
}
.cousel_box_state_wrap{
  text-align: center;
  line-height: 30px;
  display: flex;
  gap:10px;
  justify-content: center;
  padding:0px 10px;
}
.cousel_box_state{
  flex-grow: 1;
  text-align: center;
  line-height: 30px;
  background: #fff;
  color:#000;
  border:1px solid #ccc;
  border-radius: 10px;
  cursor: pointer;
}
.cousel_box_state.active{
  color:#ffffff;
  background: #A2B854;
}

.detail_btn_wrap{
  text-align: center;
  margin:10px 0px 5px 0px;
}
.detail_btn{
  width: 95%;
  background: #ffffff;
  color:#333333;
  line-height: 30px;
  border-radius: 10px;
  border:1px solid #ccc;
  font-weight: 500;
  cursor: pointer;
}