.wrap{
  display: flex;
}
.left_box{
  min-width: 388px;
  border:1px solid #C4C4C4;
  padding-bottom: 10px;
}
.right_box{
  flex-grow: 1;
  min-width: 500px;
  margin-left:10px;
}