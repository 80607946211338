.box_wrap{
  display: flex;
  margin-top:15px;
  gap:10px;
}
.box{
  border:1px solid #ccc;
  flex-grow: 1;
  min-height: 100px;
  width: 50%;
}
.box.box_todo_chart{
  max-width: 450px;
}
.box.box_notice{
  max-width: 450px;
}

.todo_chart_row_box{
  width: 100%;
  height: 50%;
}

.box_title_wrap{
  position: relative;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding:0 20px;
  border-bottom: 1px solid #ebebeb;
}
.box_title_right_link{
  display: inline-block;
  position: absolute;
  top:0px;
  right: 10px;
  height: 40px;
  line-height: 40px;
  font-weight: normal;
  font-size: 15px;
  color:#7b7b7b;
  cursor: pointer;
}
.box_title_cnt_span{
  color:#fff;
  padding:0px 7px 2px 7px;
  font-size: 13px;
  font-weight: normal;
  background-color: #e04805;
  border-radius: 7px;
  margin-left:10px;
}

.stu_legend_wrap{
  height: 40px;
  list-style: 30px;
  text-align: right;
}
.stu_legend_item{
  margin-left:15px;
}
.stu_legend_item:first-child{
  margin-left:0px;
}

.notice_list_wrap{
  padding:10px 20px;
}
.notice_row{
  position: relative;
  line-height: 30px;
}
.notice_row_title{
  font-size: 16px;
  font-weight: bold;
  width: 70%;
  overflow:hidden;
  text-overflow:ellipsis;
}
.notice_row_right_text{
  position: absolute;
  right:0px;
  top:0px;
  display: inline-block;
  width: 100px;
  text-align: right;
  font-size: 14px;
  color:#b1b1b1;
  font-weight: normal;
}
.notice_empty_box{
  padding:20px 0px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color:#7b7b7b;
}

@media screen and (max-width:700px) {
  .box_wrap{
    flex-direction: column;
  }
  .box{
    width: 100%;
    margin-top:10px;
  }
  .box_todo_chart{
    margin-top:30px;
  }
  .box.box_todo{
    width: 100%;
  }
  .box.box_notice{
    width: 100%;
  }

  .notice_list_wrap{
    padding:10px;
  }
}