.plan_cal_middle_wrap{
  position: relative;
  min-height: 32px;
}
.plan_cal_middle_right{
  position: absolute;
  display: inline-block;
  min-width: 50px;
  right: 0px;
  bottom:5px;
}
.plan_cal_middle_right_state_span{
  display: inline-block;
  line-height: 25px;
  padding: 0 10px;
  color:#fff;
  font-size: 14px;
  background: #A2B854;
  font-weight: 400;
}

.plan_cal_middle_tab_btn{
  font-size: 14px;
  line-height: 30px;
  padding:0 25px;
  border:1px solid #ccc;
  border-radius: 5px;
  color:#7470F9;
  border-color: #7470F9;
  font-weight: 600;
}
.plan_cal_middle_tab_btn.active{
  background: #17c937;
  color:#fff;
}

.card_group_checkbox{
  position: relative;
  top: 5px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.card_list_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
}
.card_box{
  position: relative;
  min-width: 200px;
  max-width: 200px;
  min-height: 242px;
}
.card_box_back{
  position: absolute;
  left:0px;
  top:0px;
  width: 105%;
  height: 102%;
  background: #D0D0D0;
  border-radius: 10px;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.card_box_back_white{
  position: absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
}
.card_box_con{
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 38px;
}
.card_box_check_box{
  position: absolute;
  top:5px;
  left:5px;
  width: 18px;
  height: 18px;
  z-index: 1;
}
.card_box_top_wrap{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-top:10px;
}
.card_box_top_wrap .card_box_top_title{
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
}
.card_box_top_per_wrap{
  position: relative;
}
.card_box_top_per_text{
  position: absolute;
  width: 100%;
  top:26px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 2px;
}
.total_per_circle{
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top:10px;
}
.total_per_circle_box{
  position: relative;
  width: 100%;
  height: 100%;
}
.total_per_circle_svg{
  position:absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  transform: rotate( -90deg );
}

.todo_box{
  position: relative;
  margin-top:2px;
}

.mid_todo_row{
  position: relative;
  width: 100%;
  margin-top: 5px;
  background: #e4e4e4;
  line-height:25px;
  padding-left: 10px;
  cursor: pointer;
}
.mid_todo_row_bar{
  position: absolute;
  width: 5px;
  height: 25px;
  background: #7470F9;
  left: 0px;
}
.mid_todo_row_title{
  font-size: 13px;
  color:#000;
  font-weight: 500;
}
.mid_todo_row_per{
  position: absolute;
  right:5px;
  font-size: 12px;
  color:#000;
}
.mid_todo_row_home_work_file_ico{
  margin-left: 5px;
  font-size: 12px;
  color:#fff;
  font-weight: 500;
  background: #0fc40f;
  border-radius:20px;
  padding:0px 5px;
  padding-right: 6px;
}
.mid_todo_row_detail_row td{
  font-size: 12px;
  color:#555;
  line-height: 18px;
}

.top_year_month_wrap{
  width: 100%;
}

.year_span_wrap{
  display: flex;
  position: relative;
  justify-content: center;
  line-height: 38px;
}
.center_ym_span{
  font-size: 20px;
  color:#7470F9;
}
.center_ym_span select{
  color:#7470F9;
  background: #fff;
  border: 0;
  font-size: 25px;
  font-weight: bold;
  padding: 2px;
  text-align-last: center;
  -webkit-appearance:none; /* 크롬 화살표 없애기 */
  -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
  appearance:none /* 화살표 없애기 */
}
.center_ym_span select option{
  font-size:18px;
}
.center_ym_span_today_text{
  position: absolute;
  width: 100%;
  left:0px;
  top:30px;
  text-align: center;
}
.year_arrow_btn{
  display: inline-block;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  line-height: 43px;
}
.year_arrow_btn img{
  height: 30px;
  padding:5px;
  border:2px solid #ddd;
  border-radius: 5px;
}
.top_year_month_side_span{
  font-size: 17px;
  color:#505050;
  line-height: 43px;
  font-weight: 500;
}

.plan_cal_middle_right_state_span{
  display: inline-block;
  line-height: 25px;
  padding: 0 10px;
  color:#fff;
  font-size: 14px;
  background: #A2B854;
  font-weight: 400;
  cursor: pointer;
}

.plan_cal_middle_tab_btn{
  font-size: 14px;
  line-height: 30px;
  padding:0 25px;
  border:1px solid #ccc;
  border-radius: 5px;
  color:#7470F9;
  border-color: #7470F9;
  font-weight: 600;
}
.plan_cal_middle_tab_btn.active{
  background: #7470F9;
  color:#fff;
}

.card_box_attend_btn_wrap{
  position: absolute;
  bottom: 8px;
  width: 100%;
}
.card_box_attend_btn_box{
  text-align: center;
}
.attend_btn{
  display: inline-block;
  line-height: 28px;
  padding:0px 8px;
  font-size: 13px;
  font-weight: 400;
  color:#fff;
  cursor: pointer;
  background: #9c9c9c;
}
.attend_btn:hover{
  color:#fffd80;
}
.attend_in_btn{
  background: #53a500;
}
.attend_in_btn:hover{
  color:#fffd80;
}

.attend_late_btn{
  background: #EABF0B;
}
.attend_late_btn:hover{
  color:#fffd80;
}

.attend_no_btn{
  background: #F26B6F;
}
.attend_no_btn:hover{
  color:#fffd80;
}

.attend_out_btn{
  background: #7461ff;
}
.attend_out_btn:hover{
  color:#fffd80;
}

@media screen and (max-width:700px) {
  .card_box{
    min-width: 160px;
  }
  .card_list_wrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .plan_cal_middle_wrap{
    text-align: center;
  }
  .plan_cal_middle_right{
    position: relative;
    display: block;
    bottom:0px;
    text-align: center;
    margin-top:10px;
  }

  .center_ym_span{
    font-size: 18px;
  }
  .center_ym_span select{
    font-size: 20px;
  }
  .center_ym_span_today_text{
    display: none;
  }
}