.report_table{
  width: 100%;
}
.report_table table{
  width: 100%;
  border-collapse: collapse;
}
.report_table td,.report_table th{
  border:1px solid #ccc;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
}
.report_table th{
  background-color: #f3ffff;
}

.card_box_first_tr td{
  border-top: 2px solid #ddd;
}