.search_box{
  position: relative;
  text-align: center;
  line-height: 20px;
  margin:5px;
}
.pre_date_span{
  margin:0px 10px;
  font-size: 17px;
  color: #505050;
  font-weight: 500;
  cursor: pointer;
}
.select_date_span{
  margin:0px 10px;
  font-size: 20px;
  color: #7470F9;
  font-weight: 500;
  cursor: pointer;
}
.after_date_span{
  margin:0px 10px;
  font-size: 17px;
  color: #505050;
  font-weight: 500;
  cursor: pointer;
}
.sub_time_span{
  font-size: 14px;
  color:#aaaaaa;
  margin-top: 5px;
}