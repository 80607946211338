.con_wrap{
  @apply relative text-sm text-black bg-white dark:text-gray-300;
  border-color: #d0d0d0;
  width: 100%;
  padding:12px 3px;
}
.dark .con_wrap{
  background: #060708;
}

.height_bar{
  @apply absolute inline-block bg-black dark:bg-white;
  top:17px;
  width:4px;
  height:18px;
}

.con_wrap p{
  font-weight: 500;
  font-size: 14px;
}
@media screen and (max-width:700px) {
  .con_wrap{
    padding:3px;
  }
  .height_bar{
    top:5px;
  }
}