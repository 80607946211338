.card_group{
  position: relative;
  margin-top:15px;
}
.card_group:first{
  margin-top: 0px;
}
.card_group_title{
  position: relative;
  line-height: 30px;
  font-weight: 500;
  font-size: 15px;
  padding-left: 10px;
}
.card_group_title_pre_bar{
  position: absolute;
  left: 0px;
  top:8px;
  height: 16px;
  width: 3px;
  background: #000;
}

.card_group_con{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.card_box{
  position: relative;
  width: 400px;
  height: 100%;
  min-height: 200px;
  margin-left: 15px;
  margin-top:15px;
}
.card_box_con{
  width: 100%;
  background: #fff;
  border:1px solid #CBCBCB;
  border-radius:10px;
  padding:15px 12px;
  padding-bottom: 45px;
}

.card_box_table{
  border-collapse: collapse;
  width: 100%;
}
.card_box_table th,.card_box_table td{
  font-size: 13px;
  padding:0 5px;
  line-height: 30px;
}
.card_box_table th{
  font-weight: 500;
  text-align: left;
  vertical-align: baseline;
}
.card_box_stu_name{
  font-size: 15px;
}
.card_box_input{
  border:0px;
}

.card_box_btn_div{
  position: absolute;
  text-align: center;
  width: 100%;
  bottom:5px;
  padding:5px;
}


/* Write */
.score_tag_box{
  display: flex;

}
.score_tag{
  min-width: 10%;
  flex-grow: 1;
  line-height: 30px;
  background: #a6a6a6;
  color:#fff;
  font-size: 14px;
  padding:0 5px;
  margin:2px;
  text-align: center;
  cursor: pointer;
}
.score_tag.active{
  background: #00968B;
}

@media screen and (max-width:700px) {
  .card_box{
    margin-left: 3px;
    margin-top:15px;
  }
  .card_box_con{
    padding:15px 5px;
  }
}