.counsel_wrap{
  width: 1024px;
  margin:0 auto;
}
.counsel_list_wrap{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.counsel_box{
  padding:10px;
  border:1px solid #ccc;
  border-radius: 5px;
}
.counsel_box_table{
  width: 100%;
}
.counsel_box_table td,.counsel_box_table th{
  text-align: left;
  padding:2px;
}
.counsel_box .row_state_span{
  display: inline-block;
  line-height: 22px;
  height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
}
.row_manage_btn_span{
  display: inline-block;
  line-height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
}

.write_wrap{
  margin:5px 0px;
  padding:10px;
  border:1px solid #759eff;
  border-radius: 5px;
}