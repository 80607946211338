.content_td_wrap{
  position: relative;
}
.content_td_title_wrap{
  text-align: left;
  color:#7470F9;
  font-weight: bold;
  padding-left: 5px;
  cursor: pointer;
}
.content_td_center_plus_btn{
  position: absolute;
  top:-23px;
  right:2px;
  line-height: 20px;
  background:#9a9a9a;
  padding:0 6px;
  border-radius: 5px;
  color:#fff;
  font-size: 14px;
  font-weight: bold;
  white-space:nowrap;
  text-align: center;
}
.content_td_center_plus_btn:hover{
  background: #7470F9;
}
.content_td_list{
  margin-top: 2px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: none;
}
.content_td_row{
  position: relative;
  text-align: left;
  line-height: 22px;
  padding:0 5px;
  background: #707070;
  color:#fff;
  margin:5px;
  font-weight: 300;
  border-radius: 5px;
  cursor: pointer;
}
.content_td_row_sub_text{
  background: #41001d;
  border-radius: 5px;
  font-size: 11px;
  color:#fdbd88;
  font-weight: bold;
  padding:0px 3px;
  margin-left: 3px;
}
.content_td_row_state_box{
  position: absolute;
  right: 0px;
  top:0px;
  height: 100%;
  border-radius: 5px;
  font-size: 11px;
  color:#ffffff;
  cursor: pointer;
  padding: 0px 3px;
  border:1px solid #0000001b;
}
.content_td_row_counsel_request{
  display: inline-block;
  background: #8e8e8e;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  padding: 2px;
  margin-left: 2px;
}
@media screen and (max-width:700px) {
  .content_td_row{
    font-size: 12px;
    padding:0px;
    text-align: center;
    width: 60px;
  }
  .content_td_row_state_box{
    display: none;
  }
  .content_td_row_sub_text{
    display: none;
  }
  .content_td_center_plus_btn{
    padding:0 3px;
    font-size: 13px;
  }
}