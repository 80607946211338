.title_wrap{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.check_list_wrap{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap:5px;
  padding:0px 10px;
}
.check_item{
  background: #e5e5e5;
  font-size: 16px;
  padding:0px 10px;
  cursor: pointer;
}
.check_item.active{
  background: #063e88;
  color:#fff;
}
.item_title_span{
  margin-left:10px;
}