.day_of_week_wrap{
  display: flex;
}
.item{
  display: inline-block;
  width: 38px;
  height: 30px;
  line-height: 27px;
  border: 1px solid #ccc;
  border-left:0;
  background: #fff;
  color: #111;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
.item:first-child{
  border-left:1px solid #ccc;
}
.item.active{
  background: #AEBE00;
  color:#fff;
}