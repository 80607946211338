.gnb_area .gnb_item{
  cursor: pointer;
  white-space:nowrap;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 45px;
  color:#9d9c9c;
}
.gnb_area .gnb_item.active{
  color:#03091f;
}
.dark .gnb_area .gnb_item.active{
  @apply text-yellow-300;
}

.gnb_item_ico{
  margin-right: 5px;
  height: 16px;
}

@media screen and (max-width:1200px) {
  .gnb_area .gnb_item{
    font-size: 14px;
  }
  .gnb_area .gnb_item.active{
    border-bottom:0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .dark .gnb_area .gnb_item.active{
    border-bottom:0px;
  }
  .gnb_item_ico{
    display: none;
  }
}
@media screen and (max-width:600px) {
  .gnb_area .gnb_item{
    height: 22px;
    line-height: 22px;
    margin:0 5px;
  }
}