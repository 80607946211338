.time_row_tr td{
  vertical-align: top;
}
.stu_time_box{
  text-align: center;
  border-left:1px solid #e8e8e8;
  border-right:1px solid #e8e8e8;
}
.stu_time_box_title{
  color:#333;
}
.stu_time_box_stu_list_wrap{
  margin:5px 0px;
}