.diary_wrap{
  margin-top:2px;
}
.box{
  display: flex;
  gap:10px;
}
.box_left{
  min-width: 580px;
  max-width: 700px;
}
.box_right{
  flex-grow: 1;
  min-width: 470px;
}

.studyd_box{
  padding:10px;
  border:1px solid #ccc;
  border-radius: 5px;
}
.counsel_box{
  margin-top: 10px;
  padding:10px;
  border:1px solid #ccc;
  border-radius: 5px;
}
.write_wrap{
  margin:5px 0px;
  padding:10px;
  border:1px solid #759eff;
  border-radius: 5px;
}

.counsel_box_table{
  width: 100%;
  border-collapse: collapse;
}
.counsel_box_table td,.counsel_box_table th{
  text-align: left;
  padding:4px;
  vertical-align: top;
  border:1px solid #ddd;
  border-left: 0;
  border-right: 0;
}
.counsel_box_table tr:first-child td{
  border-top:0;
}
.counsel_box_table tr:first-child th{
  border-top:0;
}
.counsel_box_table tr:last-child td{
  border-bottom:0;
}
.counsel_box_table tr:last-child th{
  border-bottom:0;
}

.counsel_box_table textarea{
  padding:3px;
  resize: none;
  border:1px solid #ddd;
}

.row_state_span{
  display: inline-block;
  line-height: 22px;
  height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
}
.row_manage_btn_span{
  display: inline-block;
  line-height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
}