.room_wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.room_box{
  position: relative;
  border:1px solid #ccc;
  border-radius: 10px;
  margin:5px;
  display: flex;
  gap: 5px;
  min-width: 500px;
}
.room_box_stu_con{
  min-width: 300px;
  max-width: 300px;
}
.room_box_con{
  flex-grow: 1;
  min-width: 200px;
  min-height: 200px;
}

.stu_select_list_wrap{
  max-height: 20vh;
  overflow: auto;
  padding:3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  gap:4px;
}
.stu_select_stu_box{
  display: inline-block;
  padding:0px 5px;
  background: #828282;
  color:#fff;
  border-radius: 5px;
  height: 25px;
  line-height: 25px;
  font-size: 15px;
  cursor: pointer;
}

.room_box_title_bar{
  position: relative;
  padding:0px 10px;
  min-height: 50px;
}
.room_box_title{
  text-align: center;
  height: 25px;
  line-height: 25px;
  font-weight: 500;
  font-size: 16px;
}
.room_box_sub_title{
  text-align: center;
  color:#575757;
  font-size:14px
}
.room_box_middle{
  display: flex;
  gap:5px;
  min-width: 250px;
}
.room_box_desk_wrap{
  flex-grow: 1;
  position: relative;
  width: 100%;
  overflow: auto;
  padding:5px;
  min-width: 250px;
}
.room_box_msg_wrap{
  flex-grow: 1;
  min-width: 250px;
}
.room_box_msg_title{
  text-align: center;
  font-weight: bold;
  height: 25px;
  line-height: 25px;
}
.room_box_msg_row_wrap{
  overflow-y: auto;
  max-height: 40vh;
}
.room_box_msg_row{
  padding:2px 0px;
}
.room_box_bottom_bar{
  text-align: center;
  padding: 10px;
}
.desk_box_row{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.desk_box{
  position: relative;
  min-width: 80px;
  min-height: 80px;
  border-radius: 10px;
  background: #717171;
  margin: 3px;
  overflow: hidden;
}
.desk_box.desk_empty_area{
  opacity: 0.4;
}
.desk_box.desk_box_card{
  min-width: 200px;
  min-height: 150px;
}
.desk_back_per{
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background: #379D89;
  z-index: 0;
}
.desk_back_per.is_ending{
  background: #db4e15;
}
.desk_content_box{
  position: absolute;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.desk_content_box.is_use_desk{
  cursor: pointer;
}
.desk_content_box_con{
  position: relative;
  width: 100%;
  height: 100%;
  color:#fff;
}
.desk_num_span{
  position: absolute;
  top:5px;
  left:5px;
  font-size: 12px;
  color:#fff;
  font-weight: 400;
}
.desk_user_name{
  position: absolute;
  width: 100%;
  text-align: center;
  top:27px;
  left:0px;
  font-size: 18px;
  color:#fff;
  font-weight: 400;
}
.desk_card_user_name{
  position: absolute;
  top:4px;
  left:22px;
  font-size: 13px;
  color:#fff;
  font-weight: 400;
}
.desk_card_content{
  width: 100%;
  height: 100%;
  padding-top:30px;
  font-size: 15px;
  color:#fff;
  font-weight: 400;
}
.desk_card_todo_row{
  position: relative;
  color: #d2d2d2;
  font-size: 14px;
  padding-left: 5px;
  cursor: pointer;
}
.desk_card_todo_row_bar{
  position: absolute;
  left:0px;
  top:0px;
}
.desk_per_div{
  position: absolute;
  top:5px;
  right:5px;
  font-size: 12px;
  color:#fff;
  font-weight: 400;
}
.desk_per_div_num{
  font-size: 12px;
  color:#7cff7c;
  margin-right: 2px;
}
.desk_time_period{
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 5px;
  left: 0px;
  font-size: 11px;
  color:#fff;
  font-weight: 400;
}
.desk_user_find_wrap{
  position: absolute;
  width: 100%;
  text-align: center;
  top:40%;
}
.desk_user_find_wrap2{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top:0px;
  left:0px;
  background: #494949;
  padding-top: 15px;
  font-size: 15px;
  cursor: pointer;
}
.desk_user_find_wrap2:hover{
  background: #94c767;
}
.desk_user_find_wrap3{
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top:0px;
  left:0px;
}
.desk_user_find_wrap3_con{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 4px;
}
.desk_user_find_wrap3_x{
  position: absolute;
  left:0px;
  top:2%;
  display: inline-block;
  width: 100%;
  text-align: center;
  color:red;
  font-size: 50px;
}

@media screen and (max-width:700px) {
  .desk_box{
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
  .desk_user_name{
    font-size: 12px;
  }
  .desk_time_period{
    display: none;
  }
}