
.card_list_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 18px;
}
.card_box{
  position: relative;
  min-width: 200px;
  max-width: 200px;
  min-height: 190px;
}
.card_box_back{
  position: absolute;
  left:0px;
  top:0px;
  width: 105%;
  height: 102%;
  background: #D0D0D0;
  border-radius: 10px;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.card_box_back_white{
  position: absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
}
.card_box_con{
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 15px;
}
.card_box_top_wrap{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 32px;
  padding-top:10px;
}
.card_box_top_wrap .card_box_top_title{
  flex-grow: 1;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 22px;
}
.card_box_top_per_wrap{
  position: relative;
}
.card_box_top_per_text{
  position: absolute;
  width: 100%;
  top:26px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding-left: 2px;
}
.total_per_circle{
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-top:10px;
}
.total_per_circle_box{
  position: relative;
  width: 100%;
  height: 100%;
}
.total_per_circle_svg{
  position:absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  transform: rotate( -90deg );
}

.todo_box{
  position: relative;
  margin-top:2px;
}

.mid_todo_row{
  position: relative;
  width: 100%;
  margin-top: 5px;
  background: #e4e4e4;
  line-height:25px;
  padding-left: 10px;
  cursor: pointer;
}
.mid_todo_row_bar{
  position: absolute;
  width: 5px;
  height: 25px;
  background: #7470F9;
  left: 0px;
}
.mid_todo_row_title{
  font-size: 13px;
  color:#000;
  font-weight: 500;
}
.mid_todo_row_per{
  position: absolute;
  right:5px;
  font-size: 12px;
  color:#000;
}
.mid_todo_row_home_work_file_ico{
  margin-left: 5px;
  font-size: 12px;
  color:#fff;
  font-weight: 500;
  background: #0fc40f;
  border-radius:20px;
  padding:0px 5px;
  padding-right: 6px;
}
.mid_todo_row_detail_row td{
  font-size: 12px;
  color:#555;
  line-height: 18px;
}