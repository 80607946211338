.card_group{
  position: relative;
  margin-top:15px;
}
.card_group_title{
  position: relative;
  line-height: 30px;
  font-weight: 500;
  font-size: 15px;
  padding-left: 10px;
}
.card_group_title_pre_bar{
  position: absolute;
  left: 0px;
  top:8px;
  height: 16px;
  width: 3px;
  background: #000;
}

.card_group_con{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap:12px;
}
.card_box{
  position: relative;
  width: 220px;
  min-height: 290px;
}
.card_box_back{
  position: absolute;
  left: -5px;
  top:-1px;
  width: 100%;
  height: 100%;
  background: #707070;
  border-radius:10px;
}
.card_box_con_back{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  border:1px solid #CBCBCB;
  border-radius:10px;
}
.card_box_con{
  position: relative;
  width: 100%;
  padding:15px 12px;
}
.card_box_top{
  display: flex;
}
.card_box_todo_per{
  position: relative;
  flex-grow: 0;
  cursor: pointer;
}
.card_box_todo_per_circle{
  position: absolute;
  top:0px;
  left:0px;
  width: 52px;
  height: 52px;
}
.card_box_todo_per_circle_box{
  position: relative;
  width: 100%;
  height: 100%;
}
.card_box_todo_per_circle_svg{
  position:absolute;
  left:0px;
  top:0px;
  width: 100%;
  height: 100%;
  transform: rotate( -90deg );
}
.card_box_todo_per_text{
  width: 50px;
  text-align: center;
  line-height: 16px;
  margin-top:10px;
}
.card_box_todo_per_text p{
  font-size: 14px;
  font-weight: 600;
  color:#535353;
}
.card_box_title_wrap{
  flex-grow: 1;
  padding-left: 18px;
}
.card_box_title{
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.card_box_sub_title{
  color: #535353;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}
.card_box_todo{
  margin-top:5px;
}
.card_box_todo table{
  width: 100%;
  border-collapse: collapse;
}
.card_box_todo table th{
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
}
.card_box_todo_td_per_wrap{
  position: relative;
  width: 80%;
  margin:0 auto;
}
.card_box_todo_td_per_back{
  position: absolute;
  top:0px;
  left:0px;
  width: 100%;
  height: 6px;
  background: #d3d3d3;
  border-radius: 5px;
}
.card_box_todo_td_per_bar{
  position: absolute;
  top:0px;
  left:0px;
  width: 10%;
  height: 6px;
  background: #53a500;
  border-radius: 5px;
}
.card_box_attend_state{
  margin-top:10px;
}
.card_box_attend_state_title{
  font-size: 13px;
  font-weight: 500;
  color: #707070;
}
.card_box_attend_state_time{
  font-size: 12px;
  font-weight: 500;
  color: #707070;
}
.card_box_attend_btn_wrap{
  margin-top:15px;
}
.card_box_attend_btn_title{
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}
.card_box_attend_btn_box{
  margin-top: 10px;
  text-align: center;
}
.attend_btn{
  display: inline-block;
  line-height: 28px;
  padding:0px 8px;
  font-size: 13px;
  font-weight: 400;
  color:#fff;
  cursor: pointer;
  background: #9c9c9c;
}
.attend_btn:hover{
  color:#fffd80;
}
.attend_in_btn{
  background: #53a500;
}
.attend_in_btn:hover{
  color:#fffd80;
}

.attend_late_btn{
  background: #EABF0B;
}
.attend_late_btn:hover{
  color:#fffd80;
}

.attend_no_btn{
  background: #F26B6F;
}
.attend_no_btn:hover{
  color:#fffd80;
}

.attend_out_btn{
  background: #7461ff;
}
.attend_out_btn:hover{
  color:#fffd80;
}

.todo_detail_list{
  margin-top:0px;
  line-height: 23px;
}
.todo_detail_row{
  display: flex;
  width: 100%;
  gap:5px;
}
.todo_detail_ico{
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.todo_detail_ico img{
  height: 100%;
}
.todo_detail_content{
  flex-grow: 1;
  font-size: 13px;
  cursor: pointer;
}
.mid_todo_row_home_work_file_ico{
  margin-left: 5px;
  font-size: 12px;
  color:#fff;
  font-weight: 500;
  background: #0fc40f;
  border-radius:20px;
  padding:0px 5px;
  padding-right: 6px;
}