.reply-wrap{
  @apply p-2;
}
.reply-write-wrap{
  
}
.reply-write-content{
  @apply h-14 w-full p-2 bg-gray-50 dark:bg-gray-700 
    border border-gray-300 dark:border-gray-600 dark:placeholder-gray-400 
    text-gray-900 dark:text-white 
    dark:focus:ring-blue-500 
    dark:focus:border-blue-500;
}

.reply-list-wrap{
  @apply p-2;
}
.reply-list-row{
  @apply p-2;
}

.reply-list-row{
  border-bottom: 1px solid rgb(102, 102, 102);
}
.reply-row-title{
  @apply text-sm text-green-700 dark:text-green-300;
}
.reply-row-content{
  @apply text-sm text-slate-700 dark:text-slate-300;
}
.reply-row-bottom{
  @apply text-xs text-slate-300 dark:text-slate-500;
}