.wrap{
  display: flex;
}
.left_box{
  min-width: 320px;
  border:1px solid #C4C4C4;
  padding-bottom: 10px;
}
.cal_box{
  flex-grow: 1;
  min-width: 500px;
  margin-left:10px;
}

.left_tab_wrap{
  width: 100%;
  display: flex;
}
.tab_box{
  flex-grow: 1;
  width: 50%;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  text-align: center;
  color:#000;
  border-bottom: 1px solid #C4C4C4;
  font-weight: 600;
  cursor: pointer;
}
.tab_box:first-child{
  border-right: 1px solid #C4C4C4;
}
.tab_box.active{
  border-bottom:0;
  color:#3FAC96;
}

@media screen and (max-width:700px) {
  .wrap{
    flex-direction: column;
  }
  .cal_box{
    margin-left: 0px;
  }
}