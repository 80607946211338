@tailwind base;
@tailwind components;
@tailwind utilities;

@import './css/base.css';
@import './css/layout.css';
@import './css/header.css';
@import './css/gnb.css';
@import './css/snb.css';
@import './css/content.css';
@import './css/print.css';
@import './css/btn.css';
@import './css/list.css';
@import './css/list_basic.css';
@import './css/list_round.css';
@import './css/list_dashboard.css';
@import './css/list_aggrid.css';
@import './css/calendar.css';
@import './css/write.css';
@import './css/react_datepicker.css';
@import './css/popup.css';
@import './css/paging.css';
@import './css/reply.css';

@import './css/chat/chat.css';
@import './css/chat/chat_users.css';
@import './css/chat/chat_room.css';
@import './css/chat/chat_list.css';

@import './css/plan/plan_math.css';

@import './css/student/layout.css';
@import './css/student/gnb.css';
@import './css/student/snb.css';
@import './css/student/content.css';

@import './css/home/layout.css';
@import './css/home/list.css';
@import './css/home/content.css';