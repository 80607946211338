.card_group_list_wrap{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.card_list_wrap{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.card_box{
  max-width: 400px;
  min-width: 400px;
  min-height:150px;
  border:1px solid #ccc;
  border-radius: 5px;
  padding:10px;
}