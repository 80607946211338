/*header*/
#header{
  border-bottom: 3px solid #d9e8ef;
  /* border-top: 1px solid var(--layout_border_color); */
}
.dalirant #header{
  border-bottom: 3px solid #d9e8ef;
}
.dark #header{
  background: #112;
}
#header .header_con{
  @apply relative;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}
#header .header_top_wrap{
  position: relative;
  min-height: 50px;
}
#header .logo_area{
  width: 300px;
  min-height: 40px;
  margin-left:5px;
}
#header .logo_area img{
  max-height:75px;
  max-width: 300px;
  position: absolute;
  top:5px;
}
#header .header_top_link_wrap{
  position:absolute;
  right: 10px;
  top:12px;
  display: inline-block;
  line-height: 20px;
  z-index: 1;
}
#header .header_top_link_btn{
  font-size: 14px;
  color:#000;
  cursor: pointer;
}
.dark #header .header_top_link_btn{
  color:#ffffff;
}
#header .header_top_link_btn:hover{
  color:#d9e8ef;
}
#header .header_top_split{
  font-size: 11px;
  color:#bbb;
  margin:0 5px;
}

#header .gnb_wrap{
  display: flex;
  gap:10px;
  flex-grow: 1;
}
#header .gnb_area{
  @apply flex flex-row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap:30px;
  padding-left: 20px;
  padding-right: 10px;
  width: 95%;
}
#header .site_map_btn{
  width:40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #889242;
  background-color: #fff;
  margin-left:10px;
}

.login_other_box{
  position: absolute;
  top:10px;
  right: 10px;
  width: 230px;
  line-height: 25px;
  text-align: right;
  display: inline-block;
}

@media screen and (max-width:700px) {
  #header .logo_area{
    display: none;
  }
  #header .header_top_link_wrap{
    position: relative;
    right: 0;
    top: 0;
    display: block;
    line-height: 40px;
    text-align: right;
    padding-right: 10px;
  }
  .login_other_box{
    position: relative;
    width: 100%;
    text-align: right;
    top:0px;
    right:0px;
  }
}
@media screen and (max-width:600px) {
  #header .gnb_area{
    justify-content: center;
    flex-wrap: wrap;
    line-height: 20px;
    gap:5px;
    padding-left: 0px;
    padding-right: 5px;
  }
  #header{
    height: auto;
  }
}