.sms_box_wrap{
  display: flex;
}
.left_user_box{
  min-height: 40vh;
  border:1px solid #ccc;
  padding:10px;
  flex-grow: 1;
}
.right_sms_box{
  min-height: 40vh;
  border:1px solid #ccc;
  border-left: 0;
  text-align: center;
  flex-grow: 1;
}
.sms_content_title{
  text-align: center;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  background: #eee;
  color:#000;
}
.sms_content_input{
  width: 97%;
  min-width: 200px;
  height: 200px;
  border:0px;
  font-size: 14px;
  margin:5px;
  padding:5px;
}

@media screen and (max-width:700px) {
  .sms_box_wrap{
    flex-direction: column;
  }
  .left_user_box{
    min-height: 20vh;
  }
  .right_sms_box{
    border-left: 1px solid #ccc;
    min-height: 30vh;
  }
}