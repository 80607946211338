.date_title_div{
  text-align: center;
  color:gray;
  font-size: 12px;
}
.list_wrap{
  display: flex;
  flex-direction: column;
  gap:5px;
}
.row{
  position: relative;
  border-top:1px solid #ddd;
}
.row_stu_name{
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.row_stu_name_left_box{
  min-width: 112px;
  display: flex;
  gap: 5px;
}
.row_grade_span{
  color:#cdcdcd;
}
.row_a_counseler_span{
  color:#1aaa62;
}
.row_state_span{
  display: inline-block;
  line-height: 22px;
  height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  align-self: center;
  cursor: pointer;
}
.row_title_input{
  flex-grow: 1;
  min-width:50px;
  color:#16a34c;
  resize: none;
  overflow:hidden;
  height: 27px;
  height: 27px;
  line-height: 22px;
  border: 1px solid #ddd;
  padding:0px 2px;
}
.row_manage_btn_wrap{
  text-align: right;
  min-width: 80px;
}
.row_manage_btn_span{
  display: inline-block;
  line-height: 22px;
  padding:0px 5px;
  background: gray;
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
}
.row_manage_btn_span_top{
  display: inline-block;
  line-height: 22px;
  padding:0px 2px;
  background: #505050;
  color:#fff;
  border-radius: 5px;
  cursor: pointer;
}
.row_content_wrap{
  padding:5px;
  padding-left: 20px;
}
.row_content_box{
  padding-top:5px;
  color:#707070;
}
.row_content_text_area{
  width: 95%;
  height: 20px;
  border:1px solid #ddd;
  outline-color: #e1e1e1;
  resize: none;
  padding:5px;
  font-size: 14px;
  color:#1c1c1c;
  line-height: 16px;
}
.row_content_btn_wrap{
  text-align: center;
  line-height: 20px;
}
.row_content_file_row{
  margin-top:5px;
  text-align: center;
}
.row_content_file_row_thum{
  height: 110px;
}