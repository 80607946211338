.week_search_wrap{
  background: #585858;
}
.week_search_box{
  @apply text-white text-center p-2;
}

.year_span{
  @apply mr-2;
}
.year_span select{
  @apply px-1;
  background: #585858;
  color:white;
  font-size: 15px;
  text-align-last: center;
  -webkit-appearance:none; /* 크롬 화살표 없애기 */
  -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
  appearance:none /* 화살표 없애기 */
}

.month_span{
  @apply mx-2;
}
.month_span select{
  @apply px-2;
  background: #585858;
  color:white;
  font-size: 15px;
  text-align-last: center;
  -webkit-appearance:none; /* 크롬 화살표 없애기 */
  -moz-appearance:none; /* 파이어폭스 화살표 없애기 */
  appearance:none /* 화살표 없애기 */
}

.now_month{
  color:rgb(203, 252, 119);
  font-weight: bold;
}

.week_list_box{
  @apply flex justify-center;
  border-top:1px solid gray;
}
.day_box{
  color:white;
  text-align: center;
  width: 100px;
  padding:2px;
}
.day_box:hover{
  cursor: pointer;
  background: #6b6b6b;
}
.day_box_other{
  color:gray;
}
.day_box_active{
  background: #777777;
  height: 47px;
}

.day_box_num{
  font-size: 15px;
}
.day_box_day_week{
  font-size: 13px;
}