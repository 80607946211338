.left_right_wrap_style{
  width:100%;
}
.category_title_div{
  position: relative;
  font-weight: bold;
  font-size: 15px;
  height: 35px;
  line-height: 28px;
}
.top_back_btn{
  position: absolute;
  display: inline-block;
  right:0px;
  top:3px;
  padding:2px 5px;
  font-size: 12px;
  font-weight: normal;
  background: #fff;
  border:1px solid #ddd;
  border-radius: 5px;
  height: 25px;
  line-height: 20px;
  color:#8e8e8e;
}
.video_title{
  position: relative;
  font-weight: bold;
  height: 55px;
  font-size: 15px;
  line-height: 25px;
  padding: 4px;
  padding-left:15px;
  background: #fff;
  margin-top:5px;
  margin-bottom: 10px;
}
.video_title_select_video{
  font-size: 13px;
  font-weight: normal;
  overflow: hidden;
  width: 65vw;
  color:#bebebe;
}
.video_title_now_time_span{
  position: absolute;
  right: 2px;
  bottom: 0px;
  font-size: 11px;
  color:#bebebe;
  font-weight: normal;
}
.video_title_select_video_pre{
  color:#bebebe;
}
.video_title_pre_after_btn_span{
  position: absolute;
  right: 2px;
  top: 10px;
  color:#A1AC36;
  font-size: 13px;
}
.video_state_span{
  margin-left:3px;
  color:#a1ac36;
  font-weight: normal;
}
.content_style{
  border:"1px solid #ccc";
  border-radius:10px;
  color:#666;
  padding: 10px;
  min-height:40px;
  display:none;
}
.section_wrap_style{
  width:"100%";
}
.section_head_style{
  position:relative;
  width:100%;
  height:28px;
  font-size:14px;
  padding: 4px;
  padding-left:10px;
  background: #717923;
  color:#fff;
  cursor:pointer;
  line-height: 20px;
}
.video_item_style{
  padding:10px 15px;
  padding-bottom:25px;
  position:relative;
  cursor: pointer;
  border:"1px solid #ddd";
  border-radius:4px;
  background: #fff;
  border: 1px solid #ddd;
  border-top:0px;
}
.video_complete{
  background: #eaeae7;
}
.video_item_active{
  background: #d9e08a;
}
.video_item_style:hover{
  background: #d9e08a;
}
.video_item_title_wrap{
  overflow: hidden;
  width: 80vw;
}
.video_item_total_time{
  position:absolute;
  right:15px;
  bottom:5px;
  font-size:12px;
  color:#aaa;
}
.video_item_start_end_date{
  position:absolute;
  left:15px;
  bottom:5px;
  font-size:12px;
  color:#999;
}
.video_item_per_text_pre_ico{
  position:absolute;
  left:71vw;
  top:23px;
  z-index:99;
  width: 13px;
  height: 13px;
  border-radius: 6px;
}
.video_item_per_text{
  position:absolute;
  right:15px;
  top:24px;
  z-index:99;
  font-size: 13px;
  color:#a1ac36;
}
.video_item_per_wrap{
  position: relative;
  height: 4px;
  background: #F1F2F6;
  margin-top: 5px;
  width:65vw
}
.video_item_per_bar{
  width: 0%;
  height: 100%;
  text-align: center;
  position:absolute;
  left:0px;
  top:0px;
  background: #a1ac36;
}