.move_btn_wrap{
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  min-height: 200px;
}
.move_btn_box{
  flex-grow: 1;
  text-align: center;
}
.move_btn{
  text-align: center;
  border:2px solid #e5e5e5;
  border-radius: 10px;
  height: 30vh;
  width: 100%;
  padding:10px;
  background: #fff;
}
.move_btn:hover{
  border:2px solid #3a0eff;
}
.move_btn img{
  height: 100%;
}
@media screen and (max-width:700px) {
  .move_btn_wrap{
    flex-direction: column;
  }
  .move_btn{
    width: 100%;
    height:auto;
  }
  .move_btn img{
    width: 100%;
    height:auto;
  }
}