.cal_wrap{
  margin-top:2px;
}
.cal_wrap table{
  width: 100%;
}
.top_year_month_wrap{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.year_arrow_btn{
  padding:0px 5px;
  cursor: pointer;
}
.year_arrow_btn img{
  height: 22px;
  padding-bottom: 2px;
}
.center_ym_span{
  font-size: 18px;
  color: #3FAC97;
}
.center_ym_span select{
  color: #3FAC97;
  background: #fff;
  border: 0;
  font-weight: bold;
  padding: 2px;
  text-align-last: center;
  appearance: none /* 화살표 없애기 */;
}

.th_text{
  min-height: 25px;
  line-height: 25px;
  font-size: 15px;
}
.th_text_sat{
  color:#0058FF;
}
.th_text_sun{
  color:#E22F04;
}

.td_tag{
  
}
.td_title{
  color:#C4C4C4;
  text-align: center;
  padding:3px;
  font-weight: 500;
  cursor: pointer;
}
.td_select_arr .td_title{
  background:#dcb714;
  border-radius: 10px;
  color:#fff;
}
.td_title_now_date .td_title{
  color:#49A894;
}
.td_title_select_month{
  color:#000000;
}
.td_title_select_month.td_title_sat{
  color:#0058FF;
}
.td_title_select_month.td_title_sun{
  color:#E22F04;
}