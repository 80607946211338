.top_info_wrap{
  position: relative;
  font-size: 15px;
  text-align: center;
  line-height: 27px;
}

.map_list_row{
  margin-top: 8px;
  padding:5px;
  padding-left: 10px;
  background: #eeee;
  border-radius: 10px;
  line-height: 30px;
  font-size: 18px;
  cursor: pointer;
}