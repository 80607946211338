.chat_list_title_box{
  font-weight: bold;
  position: relative;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  padding-left:10px;
  border-bottom: 1px solid rgb(222, 218, 218);
}
.chat_list_title_plus_img_box{
  position:absolute;
  right: 10px;
}
.chat_list_title_profile_img_box{
  position:absolute;
  top:4px;
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 70%;
  background: rgb(255, 255, 255);
  line-height: 32px;
}
.chat_list_title_profile_img_box img{
  width: 100%;
  object-fit: cover;
}
.chat_list_title_text_box{
  position: absolute;
  left:62px;
  top:10px;
  font-size: 16px;
  line-height: 16px;
}
.chat_list_title_person_cnt_box{
  display: inline-block;
  color:#aeaeae;
  font-size:13px;
  line-height: 16px;
  cursor: pointer;
}
.chat_list_title_person_cnt_box img{
  width:14px;
}

.chat_content_div{
  flex-basis: 300px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
}

.chat_content_div .chat_content_row{
  padding:4px;
}
.chat_content_row_user{
  position: relative;
}
.chat_content_row_thum{
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 70%;
}
.chat_content_row_thum img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat_content_row_name{
  position:absolute;
  left:35px;
  top:5px;
}
.chat_content_div .chat_content_row .chat_content_row_msg_wrap{
  display: inline-block;
}
.chat_content_div .chat_content_row .chat_content_row_msg{
  display: inline-block;
  padding: 5px 15px;
  border-radius: 10px;
  background: #d5d5d5;
  color:#000;
  font-size: 16px;
  min-height: 34px;
}
.chat_content_div .chat_content_row_img_box img{
  max-width: 100%;
}
.chat_content_div .chat_content_row_time{
  @apply text-xs;
  color:gray;
  padding:0 2px;
}
.chat_content_div .chat_content_row_unread{
  @apply text-xs text-yellow-500 dark:text-yellow-200;
  padding:0 2px;
}

/* 내메세지 오른쪽 정렬 */
.chat_content_div .chat_content_row.my_msg_row .chat_content_row_msg{
  background: #98a801;
  color:#fff;
}
.chat_content_div .chat_content_row.my_msg_row .chat_content_row_user{
  display: none;
}
.chat_content_div .chat_content_row.my_msg_row{
  text-align: right;
}

.chat_content_div .chat_content_row_date{
  @apply text-sm;
  text-align: center;
}
.chat_content_div .chat_content_row_date_text{
  display: inline-block;
  padding: 5px 10px;
  background: #dddddd;
  color:#5d5d5d;
  border-radius: 5px;
  font-size: 14px;
}

.chat_send_div{
  @apply flex items-center;
  flex-direction: row;
  padding: 7px 5px;
  border-top:1px solid #dfdfdf;
}

.chat_send_div .chat_send_msg_div{
  flex-grow: 1;
}

.chat_send_div .chat_send_msg_div{
  text-align: center;
}
.chat_send_div .chat_send_msg_input{
  width: 97%;
  height: 40px;
  border:1px solid #dfdfdf;
  border-radius: 10px;
  padding:0px 10px;
  margin-top:6px;
  line-height: 37px;
}
.dark .chat_send_div .chat_send_msg_input{
  background: gray;
  color:#fff;
}
.chat_send_div .chat_send_msg_input:focus{
  outline: none;
}
.chat_send_div .chat_send_msg_input::-webkit-resizer {
  display: none;
}

.chat_user_list_wrap{
  position: absolute;
  top: 0px;
  left: 0px;
  width:320px;
  height: 100%;
  background: #fff;
  border:1px solid #ccc;
}
.dark .chat_user_list_wrap{
  background: rgb(82, 81, 81);
}

.chat_user_list_row{
  @apply mt-1;
  position: relative;
}
.chat_user_list_row.active_now{
  @apply text-sky-600;
}
.chat_user_list_row_right{
  position:absolute;
  right: 0px;
  top:0px;
}

.chat_user_list_bottom_box{
  @apply w-full absolute left-0 bottom-1 pt-1;
}

.chat_file_append_wrap{
  position: absolute;
  bottom: 65px;
  left: 0px;
  width:250px;
  height: 60px;
  background: #fff;
  border:1px solid #ccc;
}
.chat_file_append_content{
  position: relative;
  width: 100%;
  height:100%;
}
.chat_file_append_close{
  position: absolute;
  right: 0px;
  top:0px;
}
.chat_file_append_input{
  position: absolute;
  top: 15px;
  left: 15px;
  width: 200px;
}