:root {
  --header_height:120px;
  --min_width:350px;
  --max_width:1800px;
  --layout_border_color:#d9d9d9;
}

.main_body{
  @apply bg-white dark:bg-black dark:text-white;
}

#container .container_con{
  @apply flex dark:bg-black;
  flex-direction: column;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
  height:100%;
  padding:10px;
}

/*footer*/
#footer .footer_con{
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}

@media screen and (max-width:1200px) {
  
}
@media screen and (max-width:700px) {
  #container .container_con{
    min-width:"auto";
    width: 100%;
    height:100%;
    padding:0px;
  }
  #footer .footer_con{
    min-width:auto;
    max-width:auto;
    margin:0;
  }
}
@media screen and (max-width:600px) {
 
}
