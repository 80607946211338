.detail_wrap{
  width: 100%;
  overflow: auto;
}
.detail_wrap_con{
  min-width: 1024px;
}
.profile_img_wrap{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  overflow: hidden;
}
.profile_img_wrap img{
  height: 100px;
}
.profile_img_wrap .del_btn{
  position: absolute;
  right: 0px;
  top:0px;
  line-height: 18px;
  background: #f00;
  color:#fff;
  border-radius: 5px;
  font-size: 13px;
  padding:0px 6px;
  font-weight: 700;
  cursor: pointer;
}
.profile_img_wrap .change_btn{
  position: absolute;
  right: 0px;
  bottom: 0;
  line-height: 18px;
  background: #379D89;
  color:#fff;
  border-radius: 5px;
  font-size: 13px;
  padding:0px 6px;
  font-weight: 700;
  cursor: pointer;
}