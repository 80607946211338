#stu_header .gnb_area .gnb_item{
  @apply text-base font-bold;
  height: var(--stu_header_height);
  line-height: var(--stu_header_height);
  cursor: pointer;
  white-space:nowrap;
}
#stu_header .gnb_area .gnb_item.active{
  @apply text-bgreen2;
  border-bottom: 5px solid #9ba701;
}
.dark #stu_header .gnb_area .gnb_item.active{
  @apply text-yellow-300;
  border-bottom: 5px solid #9ba701;
}