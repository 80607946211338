.desk_table_wrap{
  width: 100%;
}
.desk_table_wrap table{
  width: 100%;
  border-collapse: collapse;
}

.desk_table_wrap table td{
  border:1px solid #ccc;
  text-align: center;
  height: 40px;
}
.desk_td_div{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 55px;
}
.desk_plus_div{
  display: inline-block;
  width:30px;
  height: 30px;
  background:#868686;
  border-radius: 15px;
  line-height: 30px;
  text-align: center;
  color:#fff;
  cursor: pointer;
  font-weight: bold;
  font-size:18px;
}
.desk_info_div{
  
}
.desk_name_input{
  width: 80%;
  text-align: center;
  color:rgb(255, 255, 255);
  height: 28px;
  background-color: #9aa700;
}

.desk_info_btn_area{
  position:absolute;
  right: 0px;
  top:0px;
  display: inline-block;
  height: 18px;
  line-height: 18px;
}
.desk_info_x_btn{
  display: inline-block;
  font-weight: bold;
  font-size:13px;
  width: 18px;
  height: 18px;
  line-height:18px;
  background:rgb(246, 58, 58);
  color:rgb(249, 249, 249);
  border-radius: 10px;
  margin-left: 2px;
  text-align: center;
  cursor: pointer;
}
.desk_info_able_btn{
  display: inline-block;
  font-weight: bold;
  font-size:12px;
  min-width: 18px;
  height: 18px;
  line-height:18px;
  background:#ff3737;
  color:rgb(249, 249, 249);
  border-radius: 5px;
  text-align: center;
  margin-left: 2px;
  padding:0px 2px;
  cursor: pointer;
}
.desk_info_able_btn.active{
  background:#3cff00;
}
.desk_able_input{
  width: 40px;
  color:#000000;
  background-color: #ffffff;
  height: 15px;
  margin-left: 1px;
  border-radius: 3px;
  border:1px solid #ccc;
}