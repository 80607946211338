.todo_list_wrap{
  margin-top: 10px;
}
.empty_box{
  border:1px solid #ccc;
  text-align: center;
  height: 45px;
  line-height: 40px;
  font-size: 12px;
  color:#ccc;
}
.todo_row{
  width:100%;
  position:relative;
  margin-top:15px;
}
.todo_row_top{
  position:relative;
  width: 100%;
  padding:5px 10px;
  display: flex;
  flex-direction: row;
  height: 60px;
  background:#5c5c5c;
  color:#fff;
  line-height: 50px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.todo_row_top_left_box{
  width:40px;
  text-align: center;
  height: 100%;
  cursor: pointer;
}
.todo_row_top_left_img_wrap{
  display: inline-block;
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background: #fff;
  line-height: 37px;
}
.todo_row_top_center_box{
  flex-grow: 1;
  padding:5px 0px;
  padding-left:8px;
  line-height: 20px;
  cursor: pointer;
}
.todo_row_top_center_box_title{
  font-size:17px;
}

.todo_row_top_right_time_wrap{
  width:90px;
  text-align: right;
  padding:5px 0px;
  line-height: 20px;
  margin-right: 25px;
  cursor: pointer;
}
.todo_row_top_right_more_wrap{
  position:absolute;
  top:0px;
  right:0px;
  width:30px;
  text-align: center;
  height: 100%;
  line-height: 40px;
  cursor: pointer;
}
.todo_row_slide_btn{
  height: 20px;
  font-size: 10px;
  text-align: center;
  border-top:1px solid #737373;
  background:#707070;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color:#fff;
  line-height: 16px;
  cursor: pointer;
}
.todo_row_slid_box{
  background: #fff;
  min-height: 30px;
  border-radius: 10px;
  border:1px solid #ddd;
}

.todo_row_file_item{
  display: inline-block;
  width: 35px;
  height: 30px;
  overflow: hidden;
}