.report_table{
  width: 100%;
}
.report_table table{
  width: 100%;
  border-collapse: collapse;
}
.report_table td,.report_table th{
  border:1px solid #ccc;
  font-size: 14px;
  text-align: center;
  line-height: 21px;
}
.report_table th{
  background-color: #f3ffff;
}
.report_table td input{
  font-size: 14px;
  background: transparent;
}
.report_table td select{
  background: transparent;
}
.report_table table td input[type=radio]{
  background: #ea5a2f;
}

.card_box_first_tr td{
  border-top: 1px solid #ddd;
}
.card_focused_tr td{
  border:2px solid #1d927b;
}

.report_table table td.disable_td{
  background-color: #e1e1e1;
}