:root {
  --stu_header_height:48px;
  --stu_max_width:1680px;
}

/*header*/
#stu_header{
  line-height: var(--stu_header_height);
  border-bottom: 1px solid #dfdfdf;
  background:#a1ac36;
}
.dark #stu_header{
  background-color: #68702d;
}
#stu_header .header_con{
  @apply flex flex-wrap flex-row;
  max-width:var(--stu_max_width);
  margin:0 auto;
  height: 60px;
  line-height: 60px;
}
#stu_header .header_left_area{
  min-width:80px;
  text-align: center;
  color:#f0f0f0;
}
#stu_header .center_area{
  @apply text-center;
  flex-grow: 1;
}
#stu_header .center_area img{
  height:45px;
}
#stu_header .gnb_area{
  @apply flex-auto flex flex-wrap flex-row pl-4 gap-x-5;
  padding-right: 100px;
  min-width: 300px;
}
#stu_header .right_area{
  min-width:60px;
  text-align: center;
  height: var(--stu_header_height);
  line-height: var(--stu_header_height)px;
}
#stu_header .right_area .header_profile_box{
  text-align: center;
}
#stu_header .right_area .header_profile_img{
  height: 35px;
}

/*container*/
#stu_container{
  width: 100%;
  flex-grow: 1;
  background: #f6f6f6;
  overflow: auto;
}
.dark #stu_container{
  background: #959595;
}

/*footer*/
#stu_footer{
  width: 100%;
  min-height: 50px;
}
#stu_footer .footer_con{
  max-width:var(--stu_max_width);
  margin:0 auto;
}