.chat_left_room_title_box{
  font-weight: bold;
  position: relative;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  padding-left:10px;
  border-bottom: 1px solid rgb(222, 218, 218);
}
.chat_left_room_title_plus_img_box{
  position:absolute;
  right: 10px;
}
.chat_left_room_list_div{
  min-height: 50vh;
  max-height: 50vh;
  overflow: auto;
}
.chat_left_room_row{
  @apply flex flex-row;
  height: 45px;
  margin-top:10px;
  cursor: pointer;
}
.chat_left_room_row_profile_wrap{
  @apply grow-0;
  position: relative;
  width: 70px;
  height: 45px;
  text-align: center;
}
.chat_left_room_row_profile_bar{
  position: absolute;
  left:5px;
  top:0px;
  display: inline-block;
  width:4px;
  height: 100%;
  background: #017d8c;
}
.chat_left_room_row_profile_img_box{
  display: inline-block;
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 70%;
  background: rgb(255, 255, 255);
}
.chat_left_room_row_profile_img_box img{
  width: 100%;
  object-fit: cover;
}
.chat_left_room_row_con_wrap{
  @apply grow;
  padding-left: 5px;
}
.chat_left_room_row_last_msg{
  color:gray;
}