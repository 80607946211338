.snb_area_wrap{
  position: relative;
  width: 100%;
  background: #d9e8ef;
}
.dalirant .snb_area_wrap{
  background: #d9e8ef;
}
.dark .snb_area_wrap{
  background: #313518;
}
.snb_area{
  @apply relative;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}

.snb_area_con{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.snb_area_con .snb_item{
  padding: 0 18px;
  height: 50px;
  line-height:50px;
  cursor: pointer;
  color: #9d8787;
  background: #d9e8ef;
}
.dalirant .snb_area_con .snb_item{
  background: #d9e8ef;
  color: #2d2d2d;
}
.dark .snb_area_con .snb_item{
  background: #313518;
}
.snb_area_con .snb_item:hover{
  color:#ffffff;
}
.dalirant .snb_area_con .snb_item:hover{
  color:#02061F;
}

.dark .snb_area_con .snb_item:hover{
  color:#cbffb9;
}
.snb_area_con .snb_item .snb_item_text{
  height: 100%;
  white-space:nowrap;
  font-size: 17px;
}
.snb_area_con .snb_item.active{
  color:#02061F;
  background: #ffffff;
  font-weight: 500;
}
.dark .snb_area_con .snb_item.active{
  background: #02061F;
  color:#ffffff;
}
.snb_bottom_area{
  position: fixed;
  bottom: 20px;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}
.snb_bottom_link_wrap{
  @apply pb-1;
}
.snb_bottom_link{
  @apply text-black dark:text-gray-200;
  cursor: pointer;
}

.snb_user_info_area{
  @apply flex flex-row gap-x-4 items-center px-4 py-5;
}
.snb_user_info_img_wrap{
  width:50px;
  height:50px;
  overflow: hidden;
  border-radius: 70%;
}
.snb_user_info_img_wrap img{
  width: 100%;
  object-fit: cover;
}
.snb_user_info_name_wrap{
  @apply text-black text-base dark:text-white;
}
.snb_user_info_name_text{
  @apply font-bold underline underline-offset-4;
}
.snb_user_info_btn_wrap{
  @apply text-black text-xs flex gap-x-1 pt-1  dark:text-white;
}
.snb_user_info_btn{
  cursor: pointer;
  font-size: 10px;
}

@media screen and (max-width:1200px) {
  .snb_area_wrap{
    background: #fff;
  }
  .dark .snb_area_wrap{
    background: #000;
  }

  .snb_area_con .snb_item{
    padding: 0px 7px;
    height: 28px;
    line-height:28px;
    background: #fff;
    color: #000;
  }
  .dark .snb_area_con .snb_item{
    background: #d9e8ef;
    color: #2d2d2d;
  }
  .snb_area_con .snb_item:hover{
    color:#379D89;
  }
  .snb_area_con .snb_item.active{
    color:#fff;
    background: #379D89;
  }
  .snb_area_con .snb_item .snb_item_text{
    font-size:13px;
  }
  .dark .snb_area_con .snb_item{
    border: 0px;
  }
  .snb_bottom_area{
    display: none;
  }
}